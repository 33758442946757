import React from "react";
import Wishlist from "./Wishlist";
import TradingGraph from "./TradingGraph";

const TradingPanel = ({theme, symbol}) => {
  return (
    <div className="cat">
      <Wishlist />
     <div style={{height:"600px", width:"100%"}}>
     <TradingGraph theme={theme} symbol={symbol}/>
     </div>
    </div>
  );
};

export default TradingPanel;
