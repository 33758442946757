export const binanceUrl = 'wss://cs.crypto24x7.io/spot-trade'
// export const binanceUrl = 'https://cs.crypto24x7.io:8080/spot-trade'
// export const binanceUrl = 'httpss://coin-yoga-backend.vercel.app/spot-trade'


export const ethBaseUrl = "https://cs.crypto24x7.io/eth-optiontrade"


export const userBaseUrl = "https://cs.crypto24x7.io/api"
export const spotBaseUrl = "https://cs.crypto24x7.io/api"
export const optionBaseUrl = "https://cs.crypto24x7.io/api"
export const scannerBaseUrl = "https://cs.crypto24x7.io/api"
