import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import ScannerGlobal from "./Dashboard/ScannerGlobal";
import Footer from "../Components/Footer";
import HelmetBase from "../helmet/HelmetBase";

function DashboardGlobal() {
  return (
    <>
     <HelmetBase noIndex={true} />
      <Navbar />
      {/* <br />
      <br />
      <br />
      <br /> */}
      <ScannerGlobal />
      <br />
      <br />

      <br />
      <br />
      <br />
      <br />

      <br />
      <br />

      <Footer />
    </>
  );
}

export default DashboardGlobal;
