import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
// import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
// import icon from "../Assets/Online Money Transfer.svg";
// import coin from "../Assets/Bitcoin.svg";
// import tenyear from "../Assets/10 years.svg";
// import rating from "../Assets/Rating.png";
// import dash from "../Assets/Group 1000005963.png";
// import icon2 from "../Assets/explorer-dynamic-colorx.png";
// import { Link } from "react-router-dom";
// import SliderPage from "./home page/slider/SliderPage";
import GainerAndLooser from "./home page/gainerandlooser/GainerAndLooser";
// import Explore from "./home page/Explore";
// import ChatBotComponent from "../Components/ChatBotComponent";
import HelmetBase from "../helmet/HelmetBase";
import NewSlide from "./NewSlide";
import TradingViewWidget from "./Trading/TradingViewWidget/TradingViewWidget";
import Faq from "../Components/Faq";
import { Helmet } from "react-helmet-async";
import AddJsonLdScript from "../helmet/AddJsonLdScript";
import { useTheme } from "../context/ThemeContext";
function Mainpage() {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTab2, setActiveTab2] = useState(1);
  const [tvLode, setTvLode] = useState(false)
  const {theme} = useTheme();
  const handleTabChange2 = (tabIndex) => {
    setActiveTab2(tabIndex);
  };

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    setTvLode(false)
 setTimeout(()=>{
  setTvLode(true)
 }, 3000)
  },[theme])

  const faqData = {
    title: "Frequently Asked Questions (FAQs)",
    rows: [
      {
        title: "What is cryptocurrency trading?",
        content:
          "Cryptocurrency trading involves buying and selling digital assets on exchanges to make a profit. Traders typically use technical analysis, charts, and indicators to make informed decisions.",
      },
      {
        title: "How do I get started with crypto trading?",
        content:
          "To get started, you'll need to create an account on a trusted cryptocurrency exchange, deposit funds, and start learning basic trading strategies. Our beginner guides cover all the necessary steps to begin your journey.",
      },
      {
        title: "What are the risks involved in crypto investing?",
        content:
          "Like any form of investment, cryptocurrency carries risks, including price volatility and regulatory uncertainty. It’s essential to understand the market, manage risk, and only invest what you’re willing to lose.",
      },
      {
        title: "How do I choose a cryptocurrency to invest in?",
        content:
          "Look for cryptocurrencies with solid use cases, strong communities, and a clear roadmap for development. We offer in-depth guides on how to evaluate digital assets before investing.",
      },
      {
        title: "What are crypto screeners, and how do they help?",
        content:
          "Crypto screeners are tools that allow you to filter cryptocurrencies based on various criteria like market cap, volume, price movements, and trends. They help you spot potential opportunities and make better-informed decisions.",
      },
    ],
  };

  return (
    <>
      <HelmetBase
        title={"Learn Crypto Trading & Investing - Master Crypto with Expert Guides & Tools"}
        description={
          "Discover everything you need to know about cryptocurrency trading and investing. Access beginner guides, advanced strategies, crypto screeners, and expert resources to start your journey in the crypto world today."
        }
        keywords={'home ,crypto24x7 ,24x7 ,learn crypto trading, cryptocurrency investing, crypto trading strategies, crypto screeners, spot trading crypto, cryptocurrency options, cryptocurrency trading tools, crypto investment guides, digital asset trading, crypto market analysis'}
      />

      {/* <Helmet>
        <title>
          Learn Crypto Trading & Investing - Master Crypto with Expert Guides & Tools
        </title>
        <meta
          name="description"
          content="Discover everything you need to know about cryptocurrency trading and investing. Access beginner guides, advanced strategies, crypto screeners, and expert resources to start your journey in the crypto world today."
        />
        <meta
          name="keywords"
          content="learn crypto trading, cryptocurrency investing, crypto trading strategies, crypto screeners, spot trading crypto, cryptocurrency options, cryptocurrency trading tools, crypto investment guides, digital asset trading, crypto market analysis"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            headline: `Learn Crypto Trading & Investing - Master Crypto with Expert Guides & Tools`,
            description: `Discover everything you need to know about cryptocurrency trading and investing. Access beginner guides, advanced strategies, crypto screeners, and expert resources to start your journey in the crypto world today.`,
          })}
        </script>
        <link rel="canonical" href={'https://crypto24x7.io/'} />
      </Helmet> */}

      <Navbar />

      {/* <div className='homebanner'>
    <br/>
    <br/>
    <h1>
    Sell  & Buy Crypto <br/>
with ease
<img src={icon}/>
    </h1>
    <p>This is going to be the most exiciting crypto service you have ever tried in your life, keep going </p>
  
    </div> */}
      {/* <div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          // pagination={{
          //   clickable: true,
          // }}
          // navigation={true}
          // modules={[Autoplay, Pagination, Navigation]}
          // className="mySwiper"
        >
          <SwiperSlide>
            <div className="expscreen">
              <div className="expscreen-left">
                <h1>Explore Screeners</h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                  dolor sit
                </p>
                <br />

                <Link to="/login">
                  <button>Get Started Now</button>
                </Link>
              </div>
              <div className="expscreen-right">
                <img src={dash} />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="expscreen">
              <div className="expscreen-right">
                <img src={dash} />
              </div>
              <div className="expscreen-left">
                <h1>Explore Screeners</h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                  dolor sit
                </p>
                <br />

                <Link to="/login">
                  <button>Get Started Now</button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div> */}
      {/* -------------------------Home Page Slider ----------------------------- */}
      {/* <SliderPage /> */}
      <NewSlide />
      {/* -------------------------Home Page Slider ----------------------------- */}
      <br />
      <br />

      <GainerAndLooser />

      {tvLode &&  <TradingViewWidget theme={theme}/>}

     

      {/* <br /> */}
      {/* <Explore /> */}

      {/* <br /> */}
      <br />
      <div className="somethingaboutus">
        <br />
        <br />
        <h4 style={{ fontSize: 36, fontWeight: 700, textAlign: "center" }}>
          Why Start Your Crypto Journey with Us?
        </h4>
        <br />
        <br />
        <br />

        <div className="something">
          {/* <div>
            <img src={tenyear} />
          </div> */}

          <div className="somting3">
            <p>
              • <b>Expert Insights:</b> Get the latest tips and tricks from
              seasoned traders. <br />• <b>Comprehensive Resources:</b> From
              beginner guides to advanced strategies, we’ve got you covered.{" "}
              <br />• <b>Tools and Reviews:</b> Access reviews of the best
              crypto screeners and trading platforms. <br />•{" "}
              <b>User-Friendly Resources:</b> Our tutorials and articles are
              designed to simplify complex topics. <br />•{" "}
              <b>Real-Time Updates:</b> Stay informed with the latest market
              trends and news. <br />• <b>Community Support:</b> Join a growing
              community of traders and investors sharing insights and tips.{" "}
              <br />
              The cryptocurrency market offers a vast array of options for
              traders to choose from. With the right tools and resources, one
              can master trading, build a robust investment portfolio, and
              explore our advanced options. Start learning, trading, and
              investing today, as the future is digital and your journey begins.
            </p>
          </div>
          {/* <div className="somting2">
            <p>
              Your Trusted Guide for Balanced
              <br />
              <span>Mindful Crypto Investing.</span>
            </p>
          </div> */}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      {/* 
      <div className="rattings">
        <div className="rattings-left">
          <div className="rattings-left-div">
            <div className="rattings-left-text">
              <h1>$12B+</h1>
              <p>
                Assets <br />
                management
              </p>
            </div>
            <br />

            <div className="rattings-left-text">
              <h1>$12B+</h1>
              <p>
                Assets <br />
                management
              </p>
            </div>
          </div>

          <br />
          <div className="rattings-left-div">
            <div className="rattings-left-text">
              <h1>$12B+</h1>
              <p>
                Assets <br />
                management
              </p>
            </div>
            <br />

            <div className="rattings-left-text">
              <h1>$12B+</h1>
              <p>
                Assets <br />
                management
              </p>
            </div>
          </div>
        </div>
        <div className="rattings-right">
          <img src={rating} />
        </div>
      </div> */}
      <br />

      <div className="portfolio">
        <h3 style={{ fontSize: 40, fontWeight: 700, textAlign: "center", color: `${theme==='light'?'white':'black'}` }}>
          We combine innovation with mindful strategies to guide your crypto
          investments, building a journey founded on trust, transparency, and
          financial well-being.
        </h3>
      </div>
      {/* <ChatBotComponent /> */}

      <Faq data={faqData} />

      <Footer />
      <AddJsonLdScript data={{
      "@context": "https://schema.org",
      "@type": "FundingScheme",
      name: "Crypto 24x7",
      alternateName: "learn crypto trading",
      url: "https://crypto24x7.io/",
      logo:
        "https://crypto24x7.io/static/media/newcoin.7c45fcafb6c30e84c90f.png",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "+49-17627184174",
        contactType: "customer service",
        contactOption: "TollFree",
        areaServed: "AE",
        availableLanguage: ["en", "Hindi"],
      },
      sameAs: [
        "https://www.facebook.com/profile.php?id=61565590778325&mibextid=kFxxJD",
        "https://x.com/24x7_crypto",
        "https://www.instagram.com/crypto24x7.io/profilecard/?igsh=amdweWV1Nzk5ODl3",
        "https://www.linkedin.com/company/crypto-24x7/",
        "https://pin.it/7i64aP0fW",
      ],
    }} />
      <AddJsonLdScript data={{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What is cryptocurrency trading?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Cryptocurrency trading involves buying and selling digital assets on exchanges to make a profit. Traders typically use technical analysis, charts, and indicators to make informed decisions.",
          },
        },
        {
          "@type": "Question",
          name: "How do I get started with crypto trading?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "To get started, you'll need to create an account on a trusted cryptocurrency exchange, deposit funds, and start learning basic trading strategies. Our beginner guides cover all the necessary steps to begin your journey.",
          },
        },
        {
          "@type": "Question",
          name: "What are the risks involved in crypto investing?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Like any form of investment, cryptocurrency carries risks, including price volatility and regulatory uncertainty. It’s essential to understand the market, manage risk, and only invest what you’re willing to lose.",
          },
        },
        {
          "@type": "Question",
          name: "How do I choose a cryptocurrency to invest in?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Look for cryptocurrencies with solid use cases, strong communities, and a clear roadmap for development. We offer in-depth guides on how to evaluate digital assets before investing.",
          },
        },
        {
          "@type": "Question",
          name: "What are crypto screeners, and how do they help?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Crypto screeners are tools that allow you to filter cryptocurrencies based on various criteria like market cap, volume, price movements, and trends. They help you spot potential opportunities and make better-informed decisions.",
          },
        },
      ],
    }}/>
    </>
  );
}

export default Mainpage;