import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import searchicon from '../Assets/Search.svg'
import icon from '../Assets/Group 34635.png'
import HelmetBase from '../helmet/HelmetBase'


function PremimumandSubscription() {
  return (
    <>
     <HelmetBase noIndex={true} />
        <Navbar/>
        <br/>
    <br/>
    <div className='center stockscreen'>
      <h1>Premium & Subscription</h1>
    <br/>

      <p style={{textAlign:'center'}}>The Cryptop Token is the key to unlocking the full potential of Cryptop. Get even higher earning rates and our lowest borrowing rates.</p>
      <br/>

      <div className='PSoffer-div'>
        <div className='PSoffer'>
        <img src={icon}/>
        <p>Spot Trading Fee 25% Offer</p>


        </div>
        <div className='PSoffer'>
        <img src={icon}/>
        <p>USDⓈ-M Futures Trading 25 Off</p>

        </div>
      </div>
   

    </div>
    <br/>
    <br/>
    <div className='Subscription-cards'>
    <div className='Subscription-card'>
    <div className='Subscription-heading'>
<p>Regular User</p>
</div>

<div className='Subscription-card-text'>
    
<h1 style={{textAlign:'center'}}>$0</h1>
<h2>Account Openning</h2>
<div className='line'></div>

<p> Free — ₹ 0 brokerage.</p>
<div className='line'></div>

<p>30d Trade Volume (BUSD)<br/><span>1,000,000 BUSD</span></p>

<div className='line'></div>

<p>BNB Balance<br/><span>≥ 0 BNB</span></p>

<div className='line'></div>


<p>Maker / Taker BNB 25% off <br/><span>0.0750% / 0.0750%</span></p>

<div className='line'></div>
<br/>
<button>Try free for 30 days</button>


</div>

</div>
  <div className='Subscription-card'>
    <div className='Subscription-heading'>
<p>Regular User</p>
</div>

<div className='Subscription-card-text'>
    
<h1 style={{textAlign:'center'}}>$0</h1>
<h2>Account Openning</h2>
<div className='line'></div>

<p> Free — ₹ 0 brokerage.</p>
<div className='line'></div>

<p>30d Trade Volume (BUSD)<br/><span>1,000,000 BUSD</span></p>

<div className='line'></div>

<p>BNB Balance<br/><span>≥ 0 BNB</span></p>

<div className='line'></div>


<p>Maker / Taker BNB 25% off <br/><span>0.0750% / 0.0750%</span></p>

<div className='line'></div>
<br/>
<button>Try free for 30 days</button>


</div>

</div>
 <div className='Subscription-card'>
    <div className='Subscription-heading'>
<p>Regular User</p>
</div>

<div className='Subscription-card-text'>
    
<h1 style={{textAlign:'center'}}>$0</h1>
<h2>Account Openning</h2>
<div className='line'></div>

<p> Free — ₹ 0 brokerage.</p>
<div className='line'></div>

<p>30d Trade Volume (BUSD)<br/><span>1,000,000 BUSD</span></p>

<div className='line'></div>

<p>BNB Balance<br/><span>≥ 0 BNB</span></p>

<div className='line'></div>


<p>Maker / Taker BNB 25% off <br/><span>0.0750% / 0.0750%</span></p>

<div className='line'></div>
<br/>
<button>Try free for 30 days</button>


</div>

</div>

    </div>
    <br/>
        <Footer/>
    </>
  )
}

export default PremimumandSubscription