import React, { useEffect, useState } from "react";
// import crown from "../Assets/Group 34628.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import pro from "../Assets/Group 34631.png";

import logo from "../Assets/newcoin.png";
import { useMain } from "../hooks/useMain";
import { toast } from "react-toastify";
// import profileIcon from "../Assets/picture.png";
import { useTheme } from "../context/ThemeContext";
import { Helmet } from "react-helmet-async";
import SwitchButton from "./SwitchButton";

function Navbar() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const { getUser, handleChangeLoggin, logout, handleUser } = useMain();
  const {theme, toggleTheme} = useTheme();
  const [sliderOpen, setSliderOpen] = useState(false);
  const location = useLocation();
  const toggleSlider = () => {
    setSliderOpen(!sliderOpen);
  };
  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const res = await getUser();
    console.log(res?.data);
    if (res?.data) {
      setUserData(res?.data);
      handleChangeLoggin(true);
      handleUser(res?.data);
    }
  };

  const handlogout = async () => {
    // const res = await logout();
    // if (res?.message == "Logged out") {
    sessionStorage.removeItem("user_token");
    setUserData(null);
    handleChangeLoggin(false);
    handleUser(null);
    toast.success("Logout Success");
    navigate("/");
    // }
    // console.log(res);
  };

  return (
    <>
    {/* <Helmet>
     <link rel="preload" as="image" href={logo} type="image/png" />
     <link rel="preload" as="image" href={pro} type="image/png" />
    </Helmet> */}
      {/* <div className="TopNav">
        <p>
          Buy & Sell Bitcoin, Ethereum and 100+ cryptos in minutes. Register now
          & get ₹100 cashback on your first trade of ₹500 or more!
        </p>
        <Link style={{textDecoration:'none'}} to="/PremimumandSubscription">
          <button>
            Premium <img src={crown} />{" "}
          </button>
        </Link>
      </div> */}
      <nav>
        <Link style={{ textDecoration: "none" }} to="/">
          <img className="logo" src={logo} style={theme ==='dark'?{ filter: 'brightness(2)'}:{}} />
        </Link>
        <ul>
          {/* <Link className='nonelist' to='/'>

          <li>Home</li>
          </Link> */}
          <Link
            style={{ textDecoration: "none" }}
            className="nonelist"
            to="/screener"
          >
            <li>Screener</li>
          </Link>
          {/* <Link style={{textDecoration:'none'}} className="nonelist" to="/initial">
            <li>Initial Offering </li>
          </Link> */}
          <div class="dropdown">
            <li class="dropbtn">
              Trading <i class="bi bi-caret-down"></i>
            </li>
            <div className="dropdown-content">
              <Link style={{ textDecoration: "none" }} to="/spot">
                Spot
              </Link>
              <Link style={{ textDecoration: "none" }} to="/option">
                Option
              </Link>
            </div>
          </div>

          {/* <Link  style={{textDecoration:'none'}}className="nonelist" to="/Funds">
            <li>Funds</li>
          </Link> */}
          {/* <Link style={{textDecoration:'none'}} className="nonelist" to="/community">
            <li>Community</li>
          </Link> */}
          <Link
            style={{ textDecoration: "none" }}
            className="nonelist"
            to="/upcoming"
          >
            <li>Upcoming</li>
          </Link>
          {/* <div class="dropdown">
            <li class="dropbtn">
              More <i class="bi bi-caret-down"></i>
            </li>
            <div class="dropdown-content">
              <Link style={{textDecoration:'none'}} to="/airdrop">Airdrop</Link>
              <Link style={{textDecoration:'none'}} to="/Stack">Stack</Link>
            </div>
          </div> */}
          {/* <Link to='/ARKHAM'>
            <li>More</li>
</Link> */}
        </ul>
        <div className="btn-nav">
          {userData ? (
            <div className="dropdown">
                {/* <button onClick={toggleTheme}>{theme==='light'? 'Dark': 'Light'}</button> */}
               <SwitchButton label={theme==='light'? 'Dark': 'Light'} action={toggleTheme} checked={theme==='light'? false: true}/>
              <img
                src={userData?.profilePicture || pro}
                alt="Profile"
                className="profile-icon"
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                onClick={() => navigate("/profile")}
              />

              <button
                className="dropdown-content"
                onClick={handlogout}
                style={{ right: "0px" }}
              >
                Logout
              </button>
            
              
            </div>
          ) : (
            <>
              {/* <Link to="/signup">
                <button className="nav-sign">Sign Up</button>
              </Link> */}
              {/* <button onClick={toggleTheme}>{theme==='light'? 'Dark': 'Light'}</button> */}
              <SwitchButton label={theme==='light'? 'Dark': 'Light'} action={toggleTheme} checked={theme==='light'? false: true}/>
              <Link to="/login">
                <button className="nav-login">Login</button>
              </Link>
            </>
          )}
          {/* <Link to="/signup">
            <button className="nav-sign">Sign Up</button>
          </Link>
          <Link to="/login">
            <button className="nav-login">Login</button>
          </Link> */}
        </div>
        <i class="bi bi-list" onClick={toggleSlider}></i>

        <div className={`slider ${sliderOpen ? "slider-open" : ""}`}>
          <a
            href="#"
            style={{ backgroundColor: "white" }}
            className="closebtn"
            onClick={toggleSlider}
          >
            &times;
          </a>
          <div style={{position:'absolute',top:25, left:'25px', color:'black'}}>
          <SwitchButton label={theme==='light'? 'Dark': 'Light'} action={toggleTheme} checked={theme==='light'? false: true}/>
          </div>
          <Link className={getActiveClass("/screener")} to="/screener">
            Screener
          </Link>
          {/* <Link className={getActiveClass("/initial")} to="/initial">Initial Offering</Link> */}
          <div class="dropdown dropdown2">
            <li class="dropbtn dropbtn2">
              <p>Trading</p>

              <i class="bi bi-caret-down"></i>
            </li>
            <div class="dropdown-content">
              <Link style={{ textDecoration: "none" }} to="/spot">
                Spot
              </Link>
              <Link style={{ textDecoration: "none" }} to="/option">
                Option
              </Link>
            </div>
          </div>

          {/* <Link className={getActiveClass("/Funds")} to="/Funds">Funds</Link> */}
          <Link className={getActiveClass("/community")} to="/community">
            Community
          </Link>
          {/* <div class="dropdown dropdown2">
            <li class="dropbtn dropbtn2">
              More <i class="bi bi-caret-down"></i>
            </li>
            <div class="dropdown-content">
              <Link style={{textDecoration:'none'}} to="/airdrop">Airdrop</Link>
              <Link style={{textDecoration:'none'}} to="/Stack">Stack</Link>
            </div>
          </div> */}

          {/* <Link className={getActiveClass("/dashboard")} to="/dashboard">Dashboard</Link>
        <Link className={getActiveClass("/Contact")} to="/Contact">Contact Us</Link> */}
          {/* <Link className={getActiveClass("/CheckOut")} to="/CheckOut">Cart</Link>
        <Link className={getActiveClass("/Shipping")} to="/Shipping">Checkout</Link> */}
          <div className="nav-side-btn">
            <Link className="lia" to="/signup">
              <button className="nav-side-btn-Sign">Sign Up</button>
            </Link>

            <Link className="lia" to="/login">
              <button className="nav-side-btn-Login">Login</button>
            </Link>
            {/* <button className="nav-side-btn-Login">Login</button> */}
          </div>
          {/* <button style={{marginTop: 10}} onClick={toggleTheme}>{theme==='light'? 'Dark': 'Light'}</button> */}
          
          
        </div>
      </nav>
    </>
  );
}

export default Navbar;
