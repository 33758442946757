import React, { useState } from 'react';
import p1  from '../Assets/image 113.png'

import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import icon from '../Assets/growth-svgrepo-com 29.png'
import bitcoin from '../Assets/btc.jpg'
import graph from '../Assets/Screenshot 2024-06-11 100707.png'
import HelmetBase from '../helmet/HelmetBase';

function TreadingDetail() {
  const [activeTab, setActiveTab] = useState(1);
      
  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  return (
    <>
     <HelmetBase noIndex={true} />
        <Navbar/>
        <br/>
    <br/>
    <div className='center stockscreen'>
      <h1 className='btc'><img src={bitcoin}/> Bitcoin <span>(BTC)</span></h1>
    <br/>

      <p style={{textAlign:'center'}}>Free equity investments and flat ₹20 intraday and F&O trades even <br/>higher earning rates and our lowest borrowing rates.</p>
      <br/>
      <h3>$ 30,484.71
+2.26%
(1D)</h3>
   
  
    </div>
    <br/>
    <br/>
    <div className='TreadingDetail-table-div'>
    <div className='TreadingDetail-table'>
    <div className='TreadingDetail-table-heading'>
<p>Multi Commodity Exchange Of India Limited</p>
<p>Minimize Indicators</p>
</div>
<br/>
<div className='TreadingDetail-table-ips'>

<div className='TreadingDetail-table-ips1'>
<div className=''>
   <div className="">
      <div className="tab-cont">
        <button
          className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
          onClick={() => handleTabChange(1)}
        >
          Lower Indicators
        </button>
        <button
          className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
          onClick={() => handleTabChange(2)}
        >
         Upper Overlays
        </button>
        <button
          className={activeTab === 3 ? 'tab-btn active' : 'tab-btn'}
          onClick={() => handleTabChange(3)}
        >
        Moving Avgs
        </button>
     

       
      </div>
      <br/>
      <div className="tab-content">
        {activeTab === 1 &&
        <>
        <div className='parameters'>
          <p><input type='checkbox' />Accumulation/Distribution</p>

          <p>
          Parameters:
          <input className='Parameters-input'/>
          </p>
        </div>

        </>}
        {activeTab === 2 && <p>
tab2
        </p> }
        {activeTab === 3 && <>

        </> }
        {activeTab === 4 && 
        <>

        </> }
      </div>
    </div>
   </div>
</div>


<div className='TreadingDetail-table-ips2'>
<div className='TreadingDetail-table-ips2-line'>
  <p>Period:</p>
  <input/>
</div>
<hr/>
<button>Update Chart</button>
</div>
<div className='TreadingDetail-table-ips1'>
<div className=''>
   <div className="">
      <div className="tab-cont">
        <button
          className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
          onClick={() => handleTabChange(1)}
        >
          Lower Indicators
        </button>
        <button
          className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
          onClick={() => handleTabChange(2)}
        >
         Upper Overlays
        </button>
        <button
          className={activeTab === 3 ? 'tab-btn active' : 'tab-btn'}
          onClick={() => handleTabChange(3)}
        >
        Moving Avgs
        </button>
     

       
      </div>
      <br/>
      <div className="tab-content">
        {activeTab === 1 &&
        <>
        <div className='parameters'>
          <p><input type='checkbox' />Accumulation/Distribution</p>

          <p>
          Parameters:
          <input className='Parameters-input'/>
          </p>
        </div>

        </>}
        {activeTab === 2 && <p>
tab2
        </p> }
        {activeTab === 3 && <>

        </> }
        {activeTab === 4 && 
        <>

        </> }
      </div>
    </div>
   </div>
</div>
</div>

    </div>

    </div>
<br/>

<br/>
<div className='grapimg'>
<img src={graph}/>
</div>
  
        <Footer/>
    </>
  )
}

export default TreadingDetail