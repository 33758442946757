import React, { useEffect, useRef, useState } from "react";
import { useMain } from "../../hooks/useMain";
import { useParams } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";

const TradingGraph = ({theme,symbol}) => {
  // const {s} = useParams()
  // const { chartCall } = useMain();
  // const [symbol, setSymbol] = useState(`BINANCE:${s ?? 'BTCUSDT'}`);
  const containerRef = useRef();
  

  // useEffect(() => {
  //   if (chartCall) {
  //     window.location.reload();
  //   }
  // }, [chartCall]);

  useEffect(
    () => {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
         {
         "autosize": true,
         "symbol": "${symbol}",
         "interval": "D",
         "timezone": "Etc/UTC",
         "theme": "${theme}",
         "style": "1",
         "locale": "en",
         "hide_side_toolbar": false,
         "allow_symbol_change": true,
         "calendar": false,
         "support_host": "https://www.tradingview.com"
       }`;
       containerRef.current.appendChild(script);
    },
    []
  );
  
  return (
    <div
      className="tradingview-widget-container"
      ref={containerRef}
      style={{ height: "100%", width: "100%" }}
    >
      
      
    </div>
  );
};

export default TradingGraph;
