import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Components/Navbar";
import searchicon from "../Assets/Search.svg";
import img1 from "../Assets/add-square-svgrepo-com 1.svg";
import img2 from "../Assets/copy-svgrepo-com 1.svg";
import img3 from "../Assets/Group 34629.png";
import icon from "../Assets/ICon (1).svg";
import icon2 from "../Assets/save-floppy-svgrepo-com 1.png";
import icon3 from "../Assets/Vector (1).png";
import icon4 from "../Assets/monitor-svgrepo-com 1.png";
import icon5 from "../Assets/result-old-svgrepo-com 1.png";
import icon6 from "../Assets/export-3-svgrepo-com 1.png";
import Footer from "../Components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMain } from "../hooks/useMain";
import ConditionQuery from "./scannerinput/ConditionQuery";
import IndicatorQuery from "./scannerinput/IndicatorQuery";
import VolumeQuery from "./scannerinput/VolumeQuery";
import RSIQuery from "./scannerinput/RSIQuery";
import MACDQuery from "./scannerinput/MADCQuery";
import BollingerQuery from "./scannerinput/BollingerQuery";
import ATRQuery from "./scannerinput/ATRQuery";
import ADXQuery from "./scannerinput/ADXQuery";
import { toast } from "react-toastify";
import ScannerModel from "../SubComponent/Scanner/ScannerModel";
import HelmetBase from "../helmet/HelmetBase";
import { Helmet } from "react-helmet";
import Faq from "../Components/Faq";
import img4 from "../Assets/blurry-man-holding-smartphone-indoors.jpg";
import img5 from "../Assets/rb_2149166905.png";
import Content from "../Components/Content";
import { FaRegChartBar } from "react-icons/fa";
import ChartModal from "./Dashboard/ChartModal";
import AddJsonLdScript from "../helmet/AddJsonLdScript";

function Home() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const itemsPerPage = 20;
  const previousPrices = useRef({});
  const { generateScanner, saveScanner, getSingleScan, userData } = useMain();
  // const [queries, setQueries] = useState([{ type: 'condition', interval: '1h', valueType: 'close', operation: '>=', compareInterval: '1h', compareValueType: 'close' }]);
  const [queries, setQueries] = useState([]);
  const [queryValidity, setQueryValidity] = useState([]);
  const [scanToggle, setScantoggle] = useState(false);
  const [scanName, setScanName] = useState("");
  const [singleScan, setSingleScan] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalaa, setShowModalaa] = useState(false);
    const [selectedSymbol, setSelectedSymbol] = useState(""); 
    
  const handleSingleScanData = async () => {
    const res = await getSingleScan(id);
    if (res?.data) {
      setSingleScan(res?.data);
      setQueries(res?.data?.queries);
    }
  };
  // console.log(queries, 'query');
  
  useEffect(() => {
    if (id) {
      handleSingleScanData();
    }
  }, [id]);

  const handleQueryChange = (index, field, value) => {
    const newQueries = [...queries];
    newQueries[index][field] = value;
    setQueries(newQueries);

    const newQueryValidity = [...queryValidity];
    newQueryValidity[index] = validateQuery(newQueries[index]);
    setQueryValidity(newQueryValidity);
  };

  const addQuery = () => {
    setQueries([...queries, { operation: "" }]);
    setQueryValidity([...queryValidity, false]);
  };

  const validateQuery = (query) => {
    for (let key in query) {
      if (
        query[key] === "" ||
        query[key] === null ||
        query[key] === undefined
      ) {
        return false;
      }
    }
    return true;
  };

  const removeQuery = (index) => {
    const newQueries = queries.filter((_, i) => i !== index);
    const newQueryValidity = queryValidity.filter((_, i) => i !== index);
    setQueries(newQueries);
    setQueryValidity(newQueryValidity);
  };

  const runScan = async () => {
    if (queryValidity.includes(false)) {
      toast.error("Please fill all the fields correctly.");
      return;
    }
    if (queries.length <= 0) {
      return toast.error("Please Add Atleast One Query.");
    }
    const result = await generateScanner({ queries: queries });
    if (result?.data) {
      setData(result?.data?.filteredResults);
    }
    console.log("Running scan with queries:", result);
  };

  const handlesaveScan = async () => {
    if (queryValidity.includes(false)) {
      toast.error("Please fill all the fields correctly.");
      return;
    }
    if (!scanName) {
      return toast.error("Please Enter Scan Name");
    }
    if (queries.length <= 0) {
      return toast.error("Please Add Atleast One Query.");
    }
    const result = await saveScanner({ queries: queries, name: scanName });
    if (result?.data) {
      toast.success("Scanner Added.");
    }
    console.log("Running scan with queries:", result);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const startIndex = (page - 1) * itemsPerPage;
  const displayedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return "↑↓";
    }
    return sortConfig.direction === "ascending" ? "↑" : "↓";
  };

  const renderQueryComponent = (query, index) => {
    switch (query.scannertype) {
      case "condition":
        return (
          <ConditionQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "indicator":
        return (
          <IndicatorQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "volume":
        return (
          <VolumeQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "rsi":
        return (
          <RSIQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "bollingerbands":
        return (
          <BollingerQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "atr":
        return (
          <ATRQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "macd":
        return (
          <MACDQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "adx":
        return (
          <ADXQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );

      default:
        return null;
    }
  };

  const faqData = {
    title: "FAQs About Crypto Screeners and Tools",
    rows: [
      {
        title: "How can I effectively track my crypto investments?",
        content:
          "Use apps like Binance or Blockfolio for seamless portfolio tracking.",
      },
      {
        title: "What are the key indicators to watch in crypto trading?",
        content: "Monitor market cap, trading volume, and price trends.",
      },
      {
        title: "Is cryptocurrency investment suitable for beginners?",
        content:
          "Yes, but beginners should start with thorough research and small investments.",
      },
      {
        title:
          "What is the best cryptocurrency to invest in during a bear market?",
        content:
          "Stablecoins and established options like Bitcoin and Ethereum are safer bets.",
      },
      {
        title: "What Is the Best Crypto Screener for Beginners?",
        content:
          "Platforms like Crypto24x7 offer user-friendly interfaces perfect for newcomers at no cost.",
      },
      {
        title: "How Often Should I Check Real-Time Crypto Prices?",
        content:
          "Traders should monitor prices at least once a day, while day traders need constant updates.",
      },
      {
        title: "Can Crypto Trends Predict Market Crashes?",
        content:
          "Trends offer insights but aren’t foolproof indicators of crashes. Diversification is key.",
      },
      {
        title: "Are Free Crypto Analysis Tools Reliable?",
        content:
          "Yes, crypto24x7 offer many free tools are accurate, though premium versions often provide enhanced features.",
      },
      {
        title: "What Is the Easiest Way to Start Crypto Day Trading?",
        content:
          "Begin with a demo account to practice using platforms like crypto24x7 or Coinbase.",
      },
      {
        title: "How Do I Choose the Best Cryptocurrency to Invest in Today?",
        content:
          "Focus on coins with strong fundamentals and active developer communities.",
      },
    ],
  };
  const contentData1 = {
    title: "Crypto Investment Strategies That Work",
    rows: [
      {
        title: "Long-Term vs. Short-Term Investment Strategies",
        content:
          "Long-term strategies focus on holding coins with strong fundamentals, while short-term strategies capitalize on market fluctuations.",
      },
      {
        title: "Balancing Risks and Rewards",
        content:
          "Investing in diverse asset classes and setting stop-loss orders are effective ways to mitigate risks while optimizing returns.",
      },
    ],
  };
  const contentData2 = {
    title: "Crypto Trading Strategies for Success",
    rows: [
      {
        title: "Day Trading Techniques",
        content:
          " Day traders often rely on technical indicators like RSI (Relative Strength Index) and MACD (Moving Average Convergence Divergence) to make split-second decisions.",
      },
      {
        title: "Swing Trading and Scalping Strategies",
        content:
          "Swing trading capitalizes on medium-term trends, while scalping focuses on small, frequent trades to build profits.",
      },
      {
        title: "Definition and Overview of Crypto Screeners",
        content:
          "A crypto screener is a digital tool that allows traders and investors to filter cryptocurrencies based on specific criteria, such as price, market cap, trading volume, or performance indicators. For instance, if you’re interested in finding coins with significant price increases in the last 24 hours, a crypto screener can display a list of such cryptocurrencies in seconds.",
      },
      {
        title: "Importance of Crypto Screeners in Modern Trading",
        content:
          "Crypto screeners are essential for making informed decisions. They provide real-time data and insights, enabling traders to identify profitable opportunities quickly. Without these tools, traders would have to sift through vast amounts of data manually—a task that's both time-consuming and prone to errors.",
      },
      {
        title: "Essential Tools for Real-Time Crypto Tracking",
        content:
          "Crypto tracking tools allow you to monitor your portfolio, set price alerts, and analyze market data in real-time. Examples include CoinMarketCap, CryptoCompare, and specialized apps like Blockfolio.",
      },
      {
        title: "Benefits of Real-Time Updates",
        content:
          "Staying updated with real-time crypto prices ensures you never miss out on opportunities to buy or sell at the right time. For day traders, this is especially critical to capitalize on short-term market fluctuations.",
      },
    ],
  };

  const dashboardHeandler = () => {
    if (userData) {
      navigate("/dashboard");
    } else {
      navigate("/login?ref=dashboard");
    }
  };

  

  const handleOpenChart = (symbol) => {
    setSelectedSymbol(symbol);
    setShowModalaa(true);
  };

  const handleCloseModal = () => setShowModalaa(false);

  return (
    <>
      {/* <Helmet>
      <title>New Titles</title>
      <meta name="description" content="Hello User" />
   
    </Helmet> */}
      <HelmetBase
        title={"Best Crypto Screener for Real-Time Market Analysis"}
        description={
          "Discover the ultimate crypto screener for tracking cryptocurrency prices, trends, and market analysis in real-time. Stay ahead with powerful tools."
        }
        keywords={
          "crypto24x7, 24x7, screener, screeners, crypto screener, cryptocurrency analysis, crypto tracking, real-time crypto prices, crypto trends, market analysis, crypto tools, crypto investment, coin search, crypto compare, crypto trading strategies, crypto investment strategy, best crypto for day trading, best crypto to buy now, best cryptocurrency to invest today"
        }
      />
      <Navbar />
      <ChartModal show={showModalaa} onHide={handleCloseModal} symbol={selectedSymbol} />

      <div className="center stockscreen" style={{marginTop: 15}}>
        <div className="background-img "></div>
        <div className="screener-first-box card">
          <h1>Free Crypto Screener for Real-Time Market Insights</h1>
          <p>
            Cryptocurrency trading has revolutionized the financial market,
            empowering investors with unprecedented opportunities. Use our free
            crypto screener to track and analyze cryptocurrency trends in
            real-time at crypto24x7. Make informed decisions with powerful
            filtering and analysis tools at no cost!
          </p>
        </div>
        {/* <div className="searchtab">
          <input placeholder="Search" style={{ outline: "none" }} />
          <img src={searchicon} />
        </div> */}
      </div>

      {/* <div className="midil">
        <h2>Using AI and Data to Predict Trends</h2>
        <p>
          Advanced AI algorithms analyze large datasets to predict price
          movements and market trends, helping traders make informed decisions.
        </p>
      </div> */}

      {/* <div className="stock-passesall-div">
        <div className="stock-passesall">
          <div className="stock-passesall-btn">
            <p>
              Stock passes all of the <br />
              below filters in cash segment:{" "}
            </p>
            <div className="stock-passesall-btn-div">
              <button>Scanner Guide</button>
              <button>Scanner Example</button>
              <button>Feedback</button>
            </div>
          </div>

          <div className="addbox">
            <img src={img1} />
            <img src={img2} />
          </div>
          <br />
          <div className="addbox">
            <button>Select A Measure Root</button>
            <button>Select A Measure Root</button>
            <button>Select A Measure Root</button>
          </div>
          <br />
        </div>
      </div> */}
      {/* 
      <div className="stock-passesall-div">
        <button onClick={() => navigate("/dashboard")}>
          List of Save Scan
        </button>
      </div> */}
      <div className="main-card-colecsan card">
        <div className="stock-passesall-main">
          <div className="stock-passesall-div">
            <div>
              {queries.map((query, index) => (
                <div
                  key={index}
                  className="query-box"
                  style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
                >
                  <select
                    required
                    value={query.scannertype}
                    onChange={(e) =>
                      handleQueryChange(index, "scannertype", e.target.value)
                    }
                    style={{
                      borderColor:
                        query.scannertype === null ||
                        query.scannertype === undefined ||
                        query.scannertype === ""
                          ? "red"
                          : "initial",
                    }}
                  >
                    <option value="">Select</option>
                    <option value="condition">Condition</option>
                    <option value="indicator">Indicator</option>
                    <option value="volume">Volume</option>
                    <option value="rsi">RSI</option>
                    <option value="bollingerbands">Bollinger Bands</option>
                    <option value="atr">ATR</option>
                    <option value="macd">MACD</option>
                    <option value="adx">ADX</option>
                    {/* Add other options as needed */}
                  </select>
                  {renderQueryComponent(query, index)}
                  <button
                    onClick={() => removeQuery(index)}
                    className="removeque"
                  >
                    Remove Query
                  </button>
                </div>
              ))}
              <button style={{ marginBottom: "10px" }} onClick={addQuery}>
                Add Query
              </button>
            </div>
          </div>

          <div className="Run-scan-div">
            <div className="Run-scan">
              <button
                className="Run-scan-btn Runscan"
                onClick={() => runScan()}
              >
                {/* <img src={icon} /> */}
                Run Scan
              </button>
              <button
                className="Run-scan-btn SaveScan"
                onClick={() => userData !== null ? setShowModal(true) : toast.error('Please Login') }
              >
                {/* <img src={icon2} /> */}
                Save Scan
              </button>
              <button className="Run-scan-btn CreateAlert">
                {/* <img src={icon3} /> */}
                Create Alert
              </button>
              <button
                className="Run-scan-btn Monitor"
                onClick={dashboardHeandler}
              >
                {/* <img src={icon4} /> */}
                Monitor & Dashboard
              </button>
              <button className="Run-scan-btn Backtest">
                {/* <img src={icon5} /> */}
                Backtest Results
              </button>
              <button className="Run-scan-btn Export">
                {/* <img src={icon6} /> */}
                Export
              </button>
                <Link to={'/public-screeners'} style={{ textDecoration: "none", color: "inherit" }}>
               <button className="Run-scan-btn Export">
                 Public Screeners
               </button>
                </Link>
               
            </div>
          </div>
          {/* {scanToggle && (
        <div className="Run-scan-div">
          <div className="Run-scan">
            <input
              type="text"
              placeholder="Scan Name"
              value={scanName}
              onChange={(e) => setScanName(e.target.value)}
            />
            <button
              className="Run-scan-btn Runscan"
              onClick={() => handlesaveScan()}
            >
              <img src={icon} />
              Add Scan
            </button>
          </div>
        </div>
      )} */}

          {/* <div className="stock-passesall-div">
        <div className="stock-passesall">
          <div className="stock-passesall-row">
            <div className="checkbox-div">
              <input type="checkbox" />
              <p>Open New Charts</p>
            </div>
            <div className="checkbox-div">
              <input type="checkbox" />
              <p>Open New Charts</p>
            </div>
          </div>
        </div>
      </div> */}

          {/* <br /> */}
          <div className="stock-passesall-div stock-passesall-div2">
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  {/* <th scope="col" onClick={() => handleSort("name")}>
                Name {getSortIcon("name")}
              </th> */}
                  <th scope="col">Symbol</th>
                  <th scope="col" onClick={() => handleSort("price")}>
                    Price {getSortIcon("price")}
                  </th>
                  <th scope="col" onClick={() => handleSort("volume")}>
                    Volume {getSortIcon("volume")}
                  </th>
                  <th scope="col">Chart</th>
                </tr>
              </thead>
              <tbody>
                {displayedData.map((item) => (
                  <tr key={item.symbol}>
                    {/* <th scope="row">{item.name}</th> */}

                    <td>{item.symbol}</td>
                    <td style={{ color: item.color }}>
                      ${item.currentCandle?.open?.toFixed(2)}
                    </td>
                    <td>{item.currentCandle?.volume?.toFixed(2)}</td>
                    <td>
                       {/* Chart icon to trigger the modal */}
                       <FaRegChartBar 
                         style={{ cursor: "pointer", width: "24px" }}
                         onClick={() => handleOpenChart(item.symbol)}
                       />
                     </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {/* Disable 'Previous' button if on the first page */}
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
              >
                Previous
              </button>
              <span>Page {page}</span>

              {/* Disable 'Next' button if on the last page */}
              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= Math.ceil(data.length / itemsPerPage)}
              >
                Next
              </button>
            </div>
          </div>
          <ScannerModel
            show={showModal}
            handleClose={() => setShowModal(false)}
            queryValidity={queryValidity}
            queries={queries}
          />
        </div>
      </div>

      {/* <div className="center stockscreen">
        <div className="background-img "></div>
        <div className="screener-first-box card">
          <h1>Free Crypto Screener for Real-Time Market Insights</h1>
          <p>
            Cryptocurrency trading has revolutionized the financial market,
            empowering investors with unprecedented opportunities. Use our free
            crypto screener to track and analyze cryptocurrency trends in
            real-time at crypto24x7. Make informed decisions with powerful
            filtering and analysis tools at no cost!
          </p>
        </div>
        <div className="searchtab">
          <input placeholder="Search" style={{ outline: "none" }} />
          <img src={searchicon} />
        </div>
      </div> */}
      <div className="midil">
        <h2>Using AI and Data to Predict Trends</h2>
        <p>
          Advanced AI algorithms analyze large datasets to predict price
          movements and market trends, helping traders make informed decisions.
        </p>
      </div>

      <Content data={contentData1} />

      <div className="cryptocurrency">
        <div className="cryptocurrency-child card">
          <h2>Cryptocurrency Analysis</h2>

          <p>
            Cryptocurrency analysis involves studying market data to make
            informed investment decisions. This includes understanding price
            movements, volume, trends, and other financial metrics. Essentially,
            it’s your map to navigating the volatile crypto market.
          </p>
          <img src={img4} alt="" className="child-img" height={300} />
        </div>
        <div className="cryptocurrency-child card">
          <img src={img5} alt="" className="child-img" height={300} />
          <h2>Fundamental Analysis vs. Technical Analysis</h2>
          <p>
            Fundamental analysis examines the intrinsic value of a
            cryptocurrency, including its technology, team, and market
            potential. In contrast, technical analysis focuses on historical
            price data and trading volumes to predict future trends. Combining
            these methods often yields the best results.
          </p>
        </div>
      </div>

      <Content data={contentData2} />

      {/* <div className="main-card-colecsan card">
        <h2 className="back-clo"></h2>
        <div style={{ padding: "10px", marginBottom: 10 }}>
          <h3 className="back-clo">
            Definition and Overview of Crypto Screeners
          </h3>
          <p className="back">
            A crypto screener is a digital tool that allows traders and
            investors to filter cryptocurrencies based on specific criteria,
            such as price, market cap, trading volume, or performance
            indicators.
          </p>
          <p className="back">
            For instance, if you’re interested in finding coins with significant
            price increases in the last 24 hours, a crypto screener can display
            a list of such cryptocurrencies in seconds.
          </p>
        </div>
      </div> */}

      <Faq data={faqData} />
      <Footer />
      <AddJsonLdScript data={{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "How can I effectively track my crypto investments?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Use apps like Binance or Blockfolio for seamless portfolio tracking."
        }
      },{
        "@type": "Question",
        "name": "What are the key indicators to watch in crypto trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Monitor market cap, trading volume, and price trends."
        }
      },{
        "@type": "Question",
        "name": "Is cryptocurrency investment suitable for beginners?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, but beginners should start with thorough research and small investments."
        }
      },{
        "@type": "Question",
        "name": "What is the best cryptocurrency to invest in during a bear market?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Stablecoins and established options like Bitcoin and Ethereum are safer bets."
        }
      },{
        "@type": "Question",
        "name": "What Is the Best Crypto Screener for Beginners?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Platforms like Crypto24x7 offer user-friendly interfaces perfect for newcomers at no cost."
        }
      },{
        "@type": "Question",
        "name": "How Often Should I Check Real-Time Crypto Prices?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Traders should monitor prices at least once a day, while day traders need constant updates."
        }
      },{
        "@type": "Question",
        "name": "Can Crypto Trends Predict Market Crashes?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Trends offer insights but aren’t foolproof indicators of crashes. Diversification is key."
        }
      },{
        "@type": "Question",
        "name": "Are Free Crypto Analysis Tools Reliable?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, crypto24x7 offer many free tools are accurate, though premium versions often provide enhanced features."
        }
      },{
        "@type": "Question",
        "name": "What Is the Easiest Way to Start Crypto Day Trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Begin with a demo account to practice using platforms like crypto24x7 or Coinbase."
        }
      },{
        "@type": "Question",
        "name": "How Do I Choose the Best Cryptocurrency to Invest in Today?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Focus on coins with strong fundamentals and active developer communities."
        }
      }]
    }}/>
    </>
  );
}

export default Home;
