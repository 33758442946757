import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

export default function Content({ data }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [active, setActive] = useState(false);

   // const contentData = {
  //   title: "FAQs About Crypto Screeners and Tools",
  //   rows: [
  //     {
  //       title: "How can I effectively track my crypto investments?",
  //       content:
  //         "Use apps like Binance or Blockfolio for seamless portfolio tracking.",
  //     },
  //     {
  //       title: "What are the key indicators to watch in crypto trading?",
  //       content: "Monitor market cap, trading volume, and price trends.",
  //     },
  //   ]
  // }

  const toggleHandler = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="main-card-colecsan card">
      <span
        onClick={() => setActive(!active)}
        className=" back-clo"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>{data.title}</h2> {active ? <FaAngleUp /> : <FaAngleDown />}{" "}
      </span>
      <div
        style={{
          maxHeight: active ? "900px" : "0",
          overflow: "hidden",
        }}
      >
        {data.rows.map((row, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <div
              className="back-clo"
              onClick={() => toggleHandler(index)}
              role="button"
              aria-expanded={activeIndex === index}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                fontSize: "20px",
                fontWeight: "bold",
                padding: "10px",
                borderRadius: "5px",
                letterSpacing: "0.5px", // Slight spacing between characters
                wordSpacing: "1px", // Slight spacing between words
              }}
            >
              <h2 style={{ fontSize: "20px", margin: 0 }}>{row.title}</h2>
              {activeIndex === index ? <FaAngleUp /> : <FaAngleDown />}
            </div>
            <div
              className="back"
              style={{
                maxHeight: activeIndex === index ? "900px" : "0",
                overflow: "hidden",
                transition: "max-height 0.3s ease",
                backgroundColor: "#f9f9f9",
                padding: "0px 10px",
                fontSize: "16px",
                lineHeight: "1.8",
                borderRadius: "5px",
                letterSpacing: "0.3px",
                wordSpacing: "1.5px",
              }}
            >
              <p style={{ margin: activeIndex === index ? "10px 0" : "0", whiteSpace: 'pre-line' }}>
                {row.content}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
