import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useMain } from '../hooks/useMain'
import ChartModal from './Dashboard/ChartModal'
import { FaRegChartBar } from "react-icons/fa";
import HelmetBase from '../helmet/HelmetBase'

export default function PublicScreenersByID() {
  const {id} = useParams()
   const [data, setData] = useState([]);
   const [page, setPage] = useState(1);
   const [showModal, setShowModal] = useState(false); // Modal state
    const [selectedSymbol, setSelectedSymbol] = useState(""); 
   const {getAllScreeners, generateScanner} = useMain();
    const [Screeners, setScreeners] = useState([])
    const [sortConfig, setSortConfig] = useState({
        key: "name",
        direction: "ascending",
      });
      const itemsPerPage = 10;

       useEffect(()=>{
        getAllScreenersHandler();
       },[id])

       useEffect(()=>{ 
         getData();
        },[Screeners])
  
    const getAllScreenersHandler =async () => {
      try{
        const result = await getAllScreeners(id);
        if (result?.data) {
          setScreeners(result?.data[0]);
        }
        console.log(result);
      }catch(error){
        console.log(error);
        
      }
    };

    const getData = async () => {
      const queries = Screeners?.queries ?? [];
      if (queries.length === 0) return;
      const result = await generateScanner({ queries });
      if (result?.data) {
        setData(result?.data?.filteredResults);
      }
    };

    const sortedData = [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

      const startIndex = (page - 1) * itemsPerPage;
      const displayedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

    const handleSort = (key) => {
      let direction = "ascending";
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
      if (sortConfig.key !== key) return "↑↓";
      return sortConfig.direction === "ascending" ? "↑" : "↓";
    };

    const handlePageChange = (newPage) => {
      setPage(newPage);
    };

    const handleOpenChart = (symbol) => {
      setSelectedSymbol(symbol);
      setShowModal(true);
    };
  
    const handleCloseModal = () => setShowModal(false);

  return (
    <>
    <HelmetBase noIndex={true} />
    <Navbar/>
    <div className="main-card-colecsan card">
        <div className="stock-passesall-main">
          <div className="stock-passesall-div">
            <h3>{Screeners.name}</h3>
            {/* <div className="pagination">
              {Array.from({ length: Math.ceil(screeners.queries.length / itemsPerPage) }, (_, index) => index + 1)
               .map((page) => (
                  <button
                    key={page}
                    className={`page-btn ${page === page? "active" : ""}`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                ))}
            </div> */}
         
    <div className="stock-passesall-div stock-passesall-div2">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col" onClick={() => handleSort("name")}>
                Name {getSortIcon("name")}
              </th>
              <th scope="col">Symbol</th>
              <th scope="col" onClick={() => handleSort("price")}>
                Price {getSortIcon("price")}
              </th>
              <th scope="col" onClick={() => handleSort("volume")}>
                Volume {getSortIcon("volume")}
              </th>
              <th>Chart</th>
            </tr>
          </thead>
          <tbody>
            {displayedData.map((item) => (
              <tr key={item.symbol}>
                <th scope="row">{item.name}</th>
                <td>{item.symbol}</td>
                <td style={{ color: item.color }}>
                  ${item.currentCandle?.open?.toFixed(2)}
                </td>
                <td>${item.currentCandle?.volume?.toFixed(2)}</td>
                <td>
                {/* Chart icon to trigger the modal */}
                <FaRegChartBar 
                  
                  style={{ cursor: "pointer", width: "24px" }}
                  onClick={() => handleOpenChart(item.symbol)}
                />
              </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            Previous
          </button>
          <span>Page {page}</span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={startIndex + itemsPerPage >= data.length}
          >
            Next
          </button>
        </div>
      </div>
      </div></div></div>
      <ChartModal show={showModal} onHide={handleCloseModal} symbol={selectedSymbol} />
    <Footer/>
    </>
  )
}
