import React, { useEffect, useState } from "react";

import Navbar from "../Components/Navbar";
import searchicon from "../Assets/Search.svg";
import img1 from "../Assets/add-square-svgrepo-com 1.svg";
import img2 from "../Assets/copy-svgrepo-com 1.svg";
import img3 from "../Assets/Group 34629.png";
import icon from "../Assets/ICon (1).svg";
import icon2 from "../Assets/save-floppy-svgrepo-com 1.png";
import icon3 from "../Assets/Vector (1).png";
import icon4 from "../Assets/monitor-svgrepo-com 1.png";
import icon5 from "../Assets/result-old-svgrepo-com 1.png";
import icon6 from "../Assets/export-3-svgrepo-com 1.png";
import Footer from "../Components/Footer";
import ashboard from "../Assets/dashboard-svgrepo-com 2.png";
import profile from "../Assets/Group 34630.png";
import trash from "../Assets/delete-filled-svgrepo-com 9.png";
import { useNavigate } from "react-router-dom";
import { useMain } from "../hooks/useMain";
import AllScanner from "./Dashboard/AllScanner";
import ScannerDetail from "./Dashboard/ScannerDetail";
import HelmetBase from "../helmet/HelmetBase";

function DashboardDetail() {
  return (
    <>
     <HelmetBase noIndex={true} />
      <Navbar />
      {/* <br />
      <br />
      <br />
      <br /> */}
      <ScannerDetail />
      <br />
      <br />

      <br />
      <br />
      <br />
      <br />

      <br />
      <br />

      <Footer />
    </>
  );
}

export default DashboardDetail;
