import React, { useEffect, useState, useRef } from "react";
import { useMain } from "../../hooks/useMain";

const Option = () => {
  const [availableCoins, setAvailableCoins] = useState([]);
  const [contract, setContract] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState("");
  const [selectedExpiry, setSelectedExpiry] = useState("");
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [optionsData, setOptionsData] = useState({});
  const [marketPrice, setMarketPrice] = useState(0);
  const {
    setoptionData,
    setIsoptionCall,
    isoptionCall,
    getoptionsymbol_binance,
  } = useMain();
  const ws = useRef(null);

  console.log(marketPrice, selectedCoin);
  // Function to fetch available coins with options trading
  const fetchAllOptionData = async () => {
    const res = await getoptionsymbol_binance();
    // console.log(res);
    if (res) {
      setAvailableCoins(res?.data?.optionContracts?.filter(e=> e?.baseAsset == "BTC" || e?.baseAsset =="ETH"));
      setContract(res?.data?.optionSymbols);
    }
  };

  useEffect(() => {
    fetchAllOptionData();
  }, []);

  // Function to connect to WebSocket and handle live updates
  const connectToWebSocket = (symbols) => {
    // Close previous WebSocket connection if exists
    if (ws.current) {
      ws.current.close();
    }

    // Create combined stream URL
    const streams = symbols
      .map((symbol) => `${symbol.toUpperCase()}@ticker`)
      .join("/");
    const wsUrl = `wss://nbstream.binance.com/eoptions/stream?streams=${streams}`;

    ws.current = new WebSocket(wsUrl);
    ws.current.onopen = () => {
      console.log(`Connected to Binance WebSocket for ${symbols.join(", ")}`);
    };

    ws.current.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      const data = parsedData.data;
      setOptionsData((prevData) => ({
        ...prevData,
        [data.s.toUpperCase()]: {
          lastPrice: data.mp,
          volume: data.v,
        },
      }));
      if (data.s.toUpperCase() === `${selectedCoin}USDT`) {
        setMarketPrice(data.mp);
      }
    };

    ws.current.onclose = () => {
      console.log("Disconnected from Binance WebSocket");
    };
  };

  const startWebSocket = () => {
    ws.current = new WebSocket("wss://stream.binance.com:9443/ws");

    ws.current.onopen = () => {
      const stream = `${selectedCoin.toLowerCase()}usdt@ticker`;
      ws.current.send(
        JSON.stringify({
          method: "SUBSCRIBE",
          params: [stream],
          id: 1,
        })
      );
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.s && data.c) {
        setMarketPrice((prevPrices) => ({
          ...prevPrices,
          [data.s]: data.c,
        }));
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };
  };

  const filterContracts = () => {
    const filtered = contract.filter(
      (c) =>
        c.underlying === `${selectedCoin}USDT` &&
        c?.expiryDate == selectedExpiry
    );
    setFilteredContracts(filtered);
    const symbols = filtered.map((c) => c.symbol);
    connectToWebSocket(symbols);
    startWebSocket()
  };

 

  useEffect(() => {
    if (selectedCoin && selectedExpiry) {
      filterContracts();
    }
  }, [selectedCoin, selectedExpiry]);

  const groupByStrikePrice = () => {
    const grouped = {};
    filteredContracts.forEach((option) => {
      if (!grouped[option.strikePrice]) {
        grouped[option.strikePrice] = { CALL: null, PUT: null };
      }
      grouped[option.strikePrice][option.side] = option;
    });

    // Convert object to array, sort by strike price, then convert back to object
    const sortedGrouped = Object.keys(grouped)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .reduce((acc, key) => {
        acc[key] = grouped[key];
        return acc;
      }, {});

    return sortedGrouped;
  };

  const groupedContracts = groupByStrikePrice();

  const getBackgroundColor = (strikePrice, optionType) => {
    const strike = parseFloat(strikePrice);
    const price = parseFloat(marketPrice[`${selectedCoin.toUpperCase()}USDT`]);
    console.log(strike, price);
    if (optionType === "CALL") {
      return strike < price ? "#d4f7d4" : "#ffd6d6"; // ITM: Light Green, OTM: Light Red
    } else if (optionType === "PUT") {
      return strike > price ? "#d4f7d4" : "#ffd6d6"; // ITM: Light Green, OTM: Light Red
    }
    return "white"; // Default color
  };

  return (
    <>
      <div className="stock-passesall-div">
        <label>Select Coin: </label>
        <select
          onChange={(e) => setSelectedCoin(e.target.value)}
          value={selectedCoin}
           className="spot-slect"
        >
          <option value="">Select a coin</option>
          {availableCoins.map((coin, index) => (
            <option key={index} value={coin?.baseAsset}>
              {coin?.baseAsset}
            </option>
          ))}
        </select>
        <br />
        <br />
        <label>Select Expiry Date: </label>
        <select
          onChange={(e) => setSelectedExpiry(e.target.value)}
          value={selectedExpiry}
            className="spot-slect"
        >
          <option value="">Select an expiry date</option>
          {[
            ...new Set(
              contract
                .filter((c) => c.underlying === `${selectedCoin}USDT`)
                .map((c) => c.expiryDate)
            ),
          ].sort().map((date, index) => (
            <option key={index} value={date}>
              {new Date(date).toLocaleDateString()}
            </option>
          ))}
        </select>
        <br />
        <br />
        <table className="table">
          <thead
            className=""
            style={{
              backgroundColor: "rgba(119, 130, 255, 1)",
              color: "white",
            }}
          >
            <tr>
              <th scope="col">Call Symbol</th>
              <th scope="col">Call Price</th>
              <th scope="col">Call Volume</th>
              <th scope="col">Call Trade</th>
              <th scope="col">Strike Price</th>
              <th scope="col">Put Trade</th>
              <th scope="col">Put Symbol</th>
              <th scope="col">Put Price</th>
              <th scope="col">Put Volume</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedContracts).map((strikePrice, index) => (
              <tr key={index}>
               
                <td
                   style={{
                    backgroundColor: getBackgroundColor(strikePrice, "CALL"),
                  }}
                >{groupedContracts[strikePrice].CALL?.symbol || "-"}</td>
                <td 
                style={{
                    backgroundColor: getBackgroundColor(strikePrice, "CALL"),
                  }}
                >
                  {optionsData[groupedContracts[strikePrice].CALL?.symbol]
                    ?.lastPrice || "-"}
                </td>
                <td 
                style={{
                    backgroundColor: getBackgroundColor(strikePrice, "CALL"),
                  }}
                >
                  {optionsData[groupedContracts[strikePrice].CALL?.symbol]
                    ?.volume || "-"}
                </td>
                <td>
                  <button
                    style={{ backgroundColor: "green", color: "white" }}
                    onClick={() => {
                      setoptionData({
                        strikePrice: strikePrice,
                        expirationDate: selectedExpiry,
                        optionType: "call",
                        cryptoSymbol:
                          groupedContracts[strikePrice].CALL?.symbol || "",
                        price:
                          optionsData[
                            groupedContracts[strikePrice].CALL?.symbol
                          ]?.lastPrice || 0,
                      });
                      setIsoptionCall(true);
                    }}
                  >
                    Call Trade
                  </button>
                </td>
                <td>{Number(strikePrice)?.toFixed(0)}</td>
                <td>
                  <button
                    style={{ backgroundColor: "red", color: "white" }}
                    onClick={() => {
                      setoptionData({
                        strikePrice: strikePrice,
                        expirationDate: selectedExpiry,
                        optionType: "put",
                        cryptoSymbol:
                          groupedContracts[strikePrice].PUT?.symbol || "",
                        price:
                          optionsData[groupedContracts[strikePrice].PUT?.symbol]
                            ?.lastPrice || 0,
                      });
                      setIsoptionCall(true);
                    }}
                  >
                    Put Trade
                  </button>
                </td>
                <td
                   style={{
                    backgroundColor: getBackgroundColor(strikePrice, "PUT"),
                  }}
                >{groupedContracts[strikePrice].PUT?.symbol || "-"}</td>
                <td
                    style={{
                      backgroundColor: getBackgroundColor(strikePrice, "PUT"),
                    }}
                >
                  {optionsData[groupedContracts[strikePrice].PUT?.symbol]
                    ?.lastPrice || "-"}
                </td>
                <td
                    style={{
                      backgroundColor: getBackgroundColor(strikePrice, "PUT"),
                    }}
                >
                  {optionsData[groupedContracts[strikePrice].PUT?.symbol]
                    ?.volume || "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Option;
