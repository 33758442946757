// Ap
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Login';
import LoginCode from './Pages/LoginCode';
import MFACode from './Pages/MFACode';
import SignUp from './Pages/SignUp';
import InitialOffering from './Pages/InitialOffering';
import Dashboard from './Pages/Dashboard';
import Profile from './Pages/Profile';
import PremimumandSubscription from './Pages/PremimumandSubscription';
import Funds from './Pages/Funds';
import Airdrop from './Pages/Airdrop';
import AirDropDetail from './Pages/AirDropDetail';
import Stacking from './Pages/Stacking';
import ARKHAM from './Pages/ARKHAM';
import Stacking2 from './Pages/Stacking2';
import CryptoDashboard from './Pages/CryptoDashboard';
import Community from './Pages/Community';
import Discussion from './Pages/Discussion';
import Mainpage from './Pages/Mainpage';
import Studys from './Pages/Studys';
import Trading from './Pages/Trading';
import Spot from './Pages/Spot';
import MainHome from './Pages/MainHome';
import TermsAndCondition from './Pages/TermsAndCondition';
import Policy from './Pages/Policy';
import TreadingDetail from './Pages/TreadingDetail';
import { WebSocketProvider } from './utils/useWebSocket';
import { OptionWebSocketProvider } from './utils/optionWebSocket';
import DashboardDetail from './Pages/DashboardDetail';
import EditScanner from './Pages/EditScanner';
import UpcomingFeatures from './Pages/UpcomingFeatures';
import DashboardGlobal from './Pages/DashboardGlobal';
import PrivateDashboardDetail from './Pages/PrivateDashboardDetail';
import LinkDashBoardDetail from './Pages/LinkDashBoardDetail';
import Partner from './Pages/Partner';
import PublicScreeners from './Pages/PublicScreeners';
import PublicScreenersByID from './Pages/PublicScreenersByID';
import PublicScreenersByCategory from './Pages/PublicScreenersByCategory';
import NotFound from './Components/NotFound';
import { ThemeProvider } from './context/ThemeContext';



function App() {
  return (
    <ThemeProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Mainpage />}/> 
        <Route path="/upcoming" element={<UpcomingFeatures />}/>
        <Route path="/screener" element={<Home />}/>
        <Route path="/screener/:id" element={<EditScanner />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/logincode" element={<LoginCode />} />
        <Route path="/mfa" element={<MFACode />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/initial" element={<InitialOffering />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/private/:id" element={<PrivateDashboardDetail />} />
        <Route path="/dashboard/linked/:id" element={<LinkDashBoardDetail />} />
        <Route path="/dashboard/:id" element={<DashboardDetail />} />
        <Route path="/dashboard/global/:id" element={<DashboardGlobal />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/PremimumandSubscription" element={<PremimumandSubscription />} />
        <Route path="/Funds" element={<Funds />} />
        <Route path="/airdrop" element={<Airdrop />} />
        <Route path="/Airdropdetail/:id" element={<AirDropDetail />} />
        <Route path="/Stack" element={<Stacking />} />
        <Route path="/Stack2" element={<Stacking2 />} />
        <Route 
          path="/spot/:s?" 
          element={
            <WebSocketProvider>
              <Trading />
            </WebSocketProvider>
          } 
        />
        <Route path="/option" element={
          <OptionWebSocketProvider>
            <Spot />
          </OptionWebSocketProvider>
        } />
        <Route path="/ARKHAM" element={<ARKHAM />} />
        <Route path="/crypto" element={<CryptoDashboard />} />
        <Route path="/community" element={<Community />} />
        <Route path="/discussion" element={<Discussion />} />
        <Route path="/main" element={<MainHome />} />
        <Route path="/t&c" element={<TermsAndCondition />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/study" element={<Studys />} />
        <Route path="/tradingdetail" element={<TreadingDetail />} />
        <Route path="/partner" element={<Partner/>} />
        <Route path="/public-screeners" element={<PublicScreeners/>}/>
        <Route path="/public-screeners/:id" element={<PublicScreenersByID/>}/>
        <Route path="/public-screeners/:category" element={<PublicScreenersByCategory/>}/>
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
