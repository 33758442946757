import React, { createContext, useContext, useEffect, useState } from 'react'

const ThemeContext = createContext();

export function ThemeProvider({children}) {
    const [theme, setTheme] = useState('light');
    const toggleTheme = ()=>{
        // setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
        let sto;
        if(theme === 'light'){
          sto = 'dark';
        }else{
          sto = 'light';
        }
        setTheme(sto);
        localStorage.setItem('theme', sto)
        // window.location.reload();
    }
    useEffect(()=>{
      if(localStorage.getItem('theme')){
        setTheme(localStorage.getItem('theme'));
      }
    },[])
    
  return (
    <ThemeContext.Provider value={{theme, toggleTheme}}>
        <div style={theme ==='dark'?{backgroundColor: 'black', color: 'white'}:{backgroundColor: 'white', color: 'black'}}>
        {children}
        </div>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext);
