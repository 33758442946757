import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import HelmetBase from '../helmet/HelmetBase'


function Studys() {
  return (
   <>
    <HelmetBase noIndex={true} />
    <Navbar/>
    <Footer/>
   </>
  )
}

export default Studys