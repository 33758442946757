import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import searchicon from "../Assets/Search.svg";
import Footer from "../Components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useMain } from "../hooks/useMain";
import ConditionQuery from "./scannerinput/ConditionQuery";
import IndicatorQuery from "./scannerinput/IndicatorQuery";
import VolumeQuery from "./scannerinput/VolumeQuery";
import RSIQuery from "./scannerinput/RSIQuery";
import MACDQuery from "./scannerinput/MADCQuery";
import BollingerQuery from "./scannerinput/BollingerQuery";
import ATRQuery from "./scannerinput/ATRQuery";
import ADXQuery from "./scannerinput/ADXQuery";
import { toast } from "react-toastify";
import HelmetBase from "../helmet/HelmetBase";

function EditScanner() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [queries, setQueries] = useState([]);
  const [queryValidity, setQueryValidity] = useState([]);
  const { generateScanner, getSingleScan, updateScan } = useMain();
  const [page, setPage] = useState(1);
  const [scanName, setScanName] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const itemsPerPage = 20;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleupdateScan = async () => {
    if (scanName == "") {
      return toast.error("Please Enter Scanner Name.");
    }
    if (queries.length <= 0) {
      return toast.error("Please Add Atleast One Query.");
    }
    if (queryValidity.includes(false)) {
      toast.error("Please fill all the fields correctly.");
      return;
    }
    const result = await updateScan(id, { name: scanName, queries: queries });
    if (result?.data) {
      toast.success("Scanner Update Done.");
      navigate(-1);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleSingleScanData = async () => {
    const res = await getSingleScan(id);
    if (res?.data) {
      setQueries(res?.data?.queries);
      setQueryValidity(new Array(queries?.length||1).fill(true));
      setScanName(res?.data?.name);
    }
  };

  console.log(queries);
  console.log(queryValidity);

  useEffect(() => {
    if (id) {
      handleSingleScanData();
    }
  }, [id]);

  const handleQueryChange = (index, field, value) => {
    const newQueries = [...queries];
    newQueries[index][field] = value;
    setQueries(newQueries);

    const newQueryValidity = [...queryValidity];
    newQueryValidity[index] = validateQuery(newQueries[index]);
    setQueryValidity(newQueryValidity);
  };

  const addQuery = () => {
    setQueries([...queries, { operation: "" }]);
    setQueryValidity([...queryValidity, false]);
  };

  const validateQuery = (query) => {
    for (let key in query) {
      if (
        query[key] === "" ||
        query[key] === null ||
        query[key] === undefined
      ) {
        return false;
      }
    }
    return true;
  };

  const removeQuery = (index) => {
    const newQueries = queries.filter((_, i) => i !== index);
    const newQueryValidity = queryValidity.filter((_, i) => i !== index);
    setQueries(newQueries);
    setQueryValidity(newQueryValidity);
  };

  const runScan = async () => {
    if (queryValidity.includes(false)) {
      toast.error("Please fill all the fields correctly.");
      return;
    }
    if (queries.length <= 0) {
      return toast.error("Please Add Atleast One Query.");
    }
    const result = await generateScanner({ queries });
    if (result?.data) {
      setData(result?.data?.filteredResults);
    }
    console.log("Running scan with queries:", result);
  };

  const renderQueryComponent = (query, index) => {
    switch (query.scannertype) {
      case "condition":
        return (
          <ConditionQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "indicator":
        return (
          <IndicatorQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "volume":
        return (
          <VolumeQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "rsi":
        return (
          <RSIQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "bollingerbands":
        return (
          <BollingerQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "atr":
        return (
          <ATRQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "macd":
        return (
          <MACDQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "adx":
        return (
          <ADXQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );

      default:
        return null;
    }
  };
  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return "↑↓";
    }
    return sortConfig.direction === "ascending" ? "↑" : "↓";
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const startIndex = (page - 1) * itemsPerPage;
  const displayedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
    <HelmetBase noIndex={true} />
      <Navbar />
      <br />
      <div className="center stockscreen">
        <h1>Edit Screener</h1>
        <input
          type="text"
          placeholder="Scanner Name"
          value={scanName}
          onChange={(e) => setScanName(e.target.value)}
        />
      </div>

      <div className="stock-passesall-div">
        <div>
          {queries.map((query, index) => (
            <div
              key={index}
              className="query-box"
              style={{ display: "flex", gap: "10px" }}
            >
              <select
                value={query.scannertype}
                onChange={(e) =>
                  handleQueryChange(index, "scannertype", e.target.value)
                }
                style={{
                  borderColor:
                    query.scannertype === null ||
                    query.scannertype === undefined ||
                    query.scannertype === ""
                      ? "red"
                      : "initial",
                }}
              >
                <option value="">Select</option>
                <option value="condition">Condition</option>
                <option value="indicator">Indicator</option>
                <option value="volume">Volume</option>
                <option value="rsi">RSI</option>
                <option value="bollingerbands">Bollinger Bands</option>
                <option value="atr">ATR</option>
                <option value="macd">MACD</option>
                <option value="adx">ADX</option>
              </select>
              {renderQueryComponent(query, index)}
              <button onClick={() => removeQuery(index)}>Remove Query</button>
            </div>
          ))}
          <button onClick={addQuery}>Add Query</button>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div className="Run-scan-div">
          <div className="Run-scan">
            <button className="Run-scan-btn Runscan" onClick={runScan}>
              Run Scan
            </button>
          </div>
        </div>

        <div className="Run-scan-div">
          <div className="Run-scan">
            <button className="Run-scan-btn Runscan" onClick={handleupdateScan}>
              Update Scan
            </button>
          </div>
        </div>
      </div>

      <div className="stock-passesall-div stock-passesall-div2">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col" onClick={() => handleSort("name")}>
                Name {getSortIcon("name")}
              </th>
              <th scope="col">Symbol</th>
              <th scope="col" onClick={() => handleSort("price")}>
                Price {getSortIcon("price")}
              </th>
              <th scope="col" onClick={() => handleSort("volume")}>
                Volume {getSortIcon("volume")}
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedData.map((item) => (
              <tr key={item.symbol}>
                <th scope="row">{item.name}</th>
                <td>{item.symbol}</td>
                <td style={{ color: item.color }}>
                  ${item.currentCandle?.open?.toFixed(2)}
                </td>
                <td>${item.currentCandle?.volume?.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            Previous
          </button>
          <span>Page {page}</span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={startIndex + itemsPerPage >= data.length}
          >
            Next
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default EditScanner;
