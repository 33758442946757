import React from 'react'
import img from '../Assets/trading-view-logo.svg'
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import HelmetBase from '../helmet/HelmetBase';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { useTheme } from '../context/ThemeContext';


export default function Partner() {
  const {theme} = useTheme();
    return (
       <>
       <HelmetBase noIndex={true} />

       <Navbar/>
     
        <Container fluid>
            <div style={{display:"flex",justifyContent:'center', padding: '10px'}}>
            <a href="https://in.tradingview.com/">  <img src={img} alt="" height={50} style={theme=='dark'?{filter:'brightness(10)'}:{}} /></a>
            </div>
          {/* Hero Section */}
          <Row className="bg-primary text-white py-5">
            <Col className="text-center">
              <h1>Partnered with TradingView for Real-Time Market Insights</h1>
              <p>Experience top-notch charting tools and data analytics directly on our platform.</p>
              <Button variant="light" size="lg">Get Started with TradingView</Button>
            </Col>
          </Row>
    
          {/* About the Partnership */}
          <Row className="my-5">
            <Col>
              <h2 className="text-center">About Our Partnership with TradingView</h2>
              <p>
                Our partnership with TradingView brings advanced charting, real-time 
                 data, and powerful technical
                analysis tools directly into our platform. Whether you're a seasoned 
                 or a beginner, these tools will
                help you make more informed decisions in the market.
              </p>
            </Col>
          </Row>
    
          {/* Features Section */}
          <Row className="text-center my-5">
            <Col>
              <h2>Features of TradingView Integration</h2>
            </Col>
          </Row>
    
          <Row className="text-center">
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>Charting Tools</Card.Title>
                  <Card.Text>
                    Access a variety of advanced chart types, including candlestick, line, and bar charts.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
    
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>Real-Time Data</Card.Title>
                  <Card.Text>
                    Stay up-to-date with real-time price changes and market updates on your dashboard.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
    
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>Indicators & Analysis</Card.Title>
                  <Card.Text>
                    Use technical indicators to perform detailed analysis and make informed trading decisions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
    
          {/* Benefits Section */}
          <Row className="my-5">
            <Col>
              <h2 className="text-center">Benefits to Users</h2>
              <ul>
                <li>Enhanced Trading Experience with real-time data and charting tools.</li>
                <li>Save time with seamless integration of TradingView features in our platform.</li>
                <li>Better market understanding with powerful analysis tools and indicators.</li>
              </ul>
            </Col>
          </Row>
    
          {/* How to Use Section */}
          <Row className="bg-light py-5">
            <Col>
              <h2 className="text-center text-black">How to Use TradingView Tools</h2>
              <p className="text-center text-black">
                Simply sign up or log in to our platform, and access the TradingView tools from your dashboard.
              </p>
              <Button variant="primary" size="lg" className="d-block mx-auto">Sign Up Now</Button>
            </Col>
          </Row>
    
          {/* Call to Action Section */}
          <Row className="text-center py-5">
            <Col>
              <h2>Get Started with TradingView Charts Now!</h2>
              <Button variant="primary" size="lg">Start Trading</Button>
            </Col>
          </Row>
        </Container>

        <Footer/>
        </>
      );
}


