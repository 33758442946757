import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useMain } from "../hooks/useMain";
import axios from "axios";
import OptionBuySell from "./Trading/OptionBuySell";
import Option from "./Trading/Option";
import OptionPaperOrder from "./OptionPaperOrder";
import arrow from "../Assets/Group 1000005963.svg";
import HelmetBase from "../helmet/HelmetBase";
import Faq from "../Components/Faq";
import Content from "../Components/Content";
import AddJsonLdScript from "../helmet/AddJsonLdScript";

function Spot() {
  const { isoptionCall, optionCall, userData } = useMain();
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  useEffect(() => {
    setToggle(false);
  }, [optionCall]);

  const faqData = {
    title: "FAQs About Option Trading Cryptocurrency",
    rows: [
      {
        title: "What is cryptocurrency option trading?",
        content:
          "Cryptocurrency option trading involves buying or selling the right (not the obligation) to trade digital assets at a specific price before a set expiration date. It’s a flexible way to trade and manage risks in volatile markets.",
      },
      {
        title: "How do call and put options work in cryptocurrency trading?",
        content:
          "•	Call options: You buy the right to purchase cryptocurrency at a specific price, anticipating the price will rise.•	Put options: You buy the right to sell cryptocurrency at a specific price, anticipating the price will fall.",
      },
      {
        title: "What are the benefits of trading cryptocurrency options?",
        content:
          "Options allow traders to hedge against risks, leverage smaller investments for higher returns, and capitalize on market movements with more flexibility than direct trading.",
      },
      {
        title: "Do I need to own cryptocurrency to trade options?",
        content:
          "No, you don’t need to own cryptocurrency to trade options. Options are derivatives that let you speculate on price movements without owning the underlying asset.",
      },
      {
        title: "How is the strike price determined in cryptocurrency options?",
        content:
          "The strike price is the predetermined price at which you can buy or sell the cryptocurrency. It is chosen when you create the option contract and depends on your market expectations.",
      },
      {
        title:
          "What is the difference between American and European cryptocurrency options?",
        content:
          "•	American options: Can be exercised at any time before the expiration date.•	European options: Can only be exercised on the expiration date.",
      },
      {
        title: "Are cryptocurrency options risky?",
        content:
          "Like any trading method, cryptocurrency options carry risks. However, they also offer tools to limit potential losses, such as setting a maximum loss amount (the premium paid).",
      },
      {
        title: "How can I start trading cryptocurrency options on Crypto24x7?",
        content:
          "Sign up on our platform, choose the cryptocurrency you want to trade, select the type of option (call or put), and define the contract terms (strike price and expiration date).",
      },
      {
        title: "What fees are associated with cryptocurrency option trading?",
        content:
          "Fees typically include the premium for purchasing an option and any platform-related charges. Check our [pricing page] for detailed information.",
      },
      {
        title: "Can I practice option trading without real money?",
        content:
          "Yes! Use our paper trading feature to simulate cryptocurrency option trading in a risk-free environment. Perfect for learning and testing strategies.",
      },
    ],
  };

 
  const Data1 = {
    title: "How to Trade Options in Cryptocurrency with Crypto24x7",
    rows: [
      {
        title:
          "What is Cryptocurrency Option Trading?",
        content:
          `Option trading cryptocurrency gives you the right-but not the obligation-to buy or sell digital assets at a predetermined price within a specified time frame. It’s a powerful tool for managing risk and capitalizing on market volatility.\n Key Benefits: \n •	Risk Management: Hedge against market fluctuations.\n•	Leverage Opportunities: Maximize returns with smaller investments.\n•	Flexibility: Trade calls or puts based on market direction.\n Start trading cryptocurrency options with Crypto24x7 and experience the freedom to tailor your trading strategy.`,
      },
      {
        title: "How to Trade Options in Cryptocurrency with Crypto24x7",
        content:
          "Trading options in cryptocurrency is easy with Crypto24x7. Here’s how it works:\n1.	Choose Your Asset: Select from a range of popular cryptocurrencies like Bitcoin, Ethereum, and more.\n2.	Pick Your Option Type: Decide between call options (expecting price increase) or put options (expecting price decrease).\n3.	Set Your Terms: Define the strike price, expiration date, and investment amount.\n4.	Monitor & Execute: Use our advanced tools to track your positions and make informed decisions.\nOur user-friendly platform ensures a seamless experience for all traders.",
      },
      {
        title:
          "Why Trade Cryptocurrency Options with Crypto24x7?",
        content:
          "•	Comprehensive Education: Access tutorials, market insights, and webinars to boost your skills.\n•	Advanced Trading Tools: Utilize charts, analytics, and indicators to stay ahead.\n•	Secure Platform: Trade with confidence, knowing your data and assets are protected.\n•	24/7 Support: Our experts are available around the clock to assist you.",
      },
      {
        title: "Options in Cryptocurrency: Unlock Your Potential",
        content:
          "Whether you're hedging risks or seeking high-reward strategies, options in cryptocurrency offer endless possibilities. At Crypto24x7, we empower traders with the tools and support they need to succeed in this fast-paced market.",
      },
      {
        title: "Start Trading Today",
        content:
          "Don’t wait to explore the benefits of option trading cryptocurrency. Sign up with Crypto24x7 and take your trading journey to the next level!",
      },
    ],
  };

  return (
    <>
      <HelmetBase
        title={"Option Trading Cryptocurrency | Trade Options with Crypto24x7"}
        description={
          "Explore option trading in cryptocurrency with Crypto24x7. Learn how to trade cryptocurrency options, manage risk, and unlock new opportunities in the crypto market."
        }
        keywords={'crypto option trading, crypto options, crypto options, cryptocurrency option trading, options for cryptocurrency, crypto currency options, options in cryptocurrency, trade crypto options, option, crypto option, crypto, crypto24x7, cryptocurrency, crypto tracking, crypto chart, chart'}

      />
      <Navbar />

      {/* <br />
      <br /> */}
      <div style={{ display: "flex", justifyContent: "end" }}>
        <div className="stockrates-div">
          <p className="stockrates-divp">Wallet</p>
          <p>
            {userData?.wallet?.currency}{" "}
            {Number(userData?.wallet?.balance || 0).toFixed(2)}
          </p>
        </div>
      </div>

      <Option />
      <br />
      <br />
      <br />
      {isoptionCall && <OptionBuySell />}
      {/* <h1>hiii</h1> */}
      <br />
      <br />
      <br />

      <div className="order-accodian">
        <div className="order-accodian-div">
          <div className="order-accodian-div-2">
            <p>Order Mangement</p>
            <img src={arrow} onClick={handleToggle} />
          </div>
          <div>{toggle && <OptionPaperOrder isCalling={toggle} />}</div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <h1 style={{ textAlign: "center" }}>
        Explore Option Trading in Cryptocurrency with Crypto24x7
      </h1>
      <p style={{ textAlign: "center", maxWidth: 1400, margin: "auto" }}>
        At Crypto24x7, we bring the exciting world of option trading
        cryptocurrency right to your fingertips. Whether you're a seasoned
        trader or new to options, our platform is designed to help you succeed
        in this dynamic market.
      </p>
      <br />
      <br />
      <Content data={Data1} />
      
      <br />

      <Faq data={faqData} />

      <Footer />
      
      <AddJsonLdScript data={{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is cryptocurrency option trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Cryptocurrency option trading involves buying or selling the right (not the obligation) to trade digital assets at a specific price before a set expiration date. It’s a flexible way to trade and manage risks in volatile markets."
        }
      },{
        "@type": "Question",
        "name": "How do call and put options work in cryptocurrency trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "•	Call options: You buy the right to purchase cryptocurrency at a specific price, anticipating the price will rise.•	Put options: You buy the right to sell cryptocurrency at a specific price, anticipating the price will fall."
        }
      },{
        "@type": "Question",
        "name": "What are the benefits of trading cryptocurrency options?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Options allow traders to hedge against risks, leverage smaller investments for higher returns, and capitalize on market movements with more flexibility than direct trading."
        }
      },{
        "@type": "Question",
        "name": "Do I need to own cryptocurrency to trade options?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, you don’t need to own cryptocurrency to trade options. Options are derivatives that let you speculate on price movements without owning the underlying asset."
        }
      },{
        "@type": "Question",
        "name": "How is the strike price determined in cryptocurrency options?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The strike price is the predetermined price at which you can buy or sell the cryptocurrency. It is chosen when you create the option contract and depends on your market expectations."
        }
      },{
        "@type": "Question",
        "name": "What is the difference between American and European cryptocurrency options?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "•	American options: Can be exercised at any time before the expiration date.•	European options: Can only be exercised on the expiration date."
        }
      },{
        "@type": "Question",
        "name": "Are cryptocurrency options risky?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Like any trading method, cryptocurrency options carry risks. However, they also offer tools to limit potential losses, such as setting a maximum loss amount (the premium paid)."
        }
      },{
        "@type": "Question",
        "name": "How can I start trading cryptocurrency options on Crypto24x7?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sign up on our platform, choose the cryptocurrency you want to trade, select the type of option (call or put), and define the contract terms (strike price and expiration date)."
        }
      },{
        "@type": "Question",
        "name": "What fees are associated with cryptocurrency option trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Fees typically include the premium for purchasing an option and any platform-related charges. Check our pricing page for detailed information."
        }
      },{
        "@type": "Question",
        "name": "Can I practice option trading without real money?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes! Use our paper trading feature to simulate cryptocurrency option trading in a risk-free environment. Perfect for learning and testing strategies."
        }
      }]
    }} />
    </>
  );
}

export default Spot;
