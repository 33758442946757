import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import profile from '../Assets/Group 34631.png'
import icon1 from '../Assets/time-filled-svgrepo-com 1.png'
import icon2 from '../Assets/calender-svgrepo-com 2.png'
import HelmetBase from '../helmet/HelmetBase'
function CryptoDashboard() {
  return (
    <>
    <HelmetBase noIndex={true} />
        <Navbar/>
        <div className='profile'>
        <div  className='profile-inner'>
          <img src={profile}/>
          <div>
            <h1>Rahul Sharma</h1>
            <p>rahul123@gmail.com</p>
            <div className='profile-inner-p'>
            <p><img src={icon1}/>Last Visit Mar 14</p>
            <p><img src={icon2}/>Last Visit Mar 14</p>


            </div>
          </div>
        </div>
        </div>
        <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <div >

   </div>
        <Footer/>
    </>
  )
}

export default CryptoDashboard