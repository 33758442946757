import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import icon from "../Assets/growth-svgrepo-com 29.png";
import search from "../Assets/Search1.svg";
import arrow from "../Assets/Group 1000005963.svg";
import graph from "../Assets/graph.png";
import TradingPanel from "./Trading/TradingPanel";
import PaperOrder from "./PaperOrder";
import BuySell from "./Trading/BuySell";
import { useMain } from "../hooks/useMain";
import Ticker from "./Trading/Ticker";
import HelmetBase from "../helmet/HelmetBase";
import Content from "../Components/Content";
import Faq from "../Components/Faq";
import AddJsonLdScript from "../helmet/AddJsonLdScript";
import { useTheme } from "../context/ThemeContext";
import { useParams } from "react-router-dom";

function Trading() {
  const { isorderCall, userData, orderCall } = useMain();
  const [toggle, setToggle] = useState(false);
  const [tvLode, setTvLode] = useState(false)
  const {s} = useParams()
  const {theme}= useTheme();
  const [symbol, setSymbol] = useState(`BINANCE:${s ?? 'BTCUSDT'}`);
   const { chartCall } = useMain();

   useEffect(() => {
    if (chartCall) {
     setSymbol(`BINANCE:${chartCall}`)
      setTvLode(false)
      setTimeout(()=>{
        setTvLode(true)
       }, 100)
    }
  },[chartCall])

   useEffect(() => {
    setTvLode(false)
    setTimeout(()=>{
    setTvLode(true)
   }, 100)
    },[theme])
    
  const handleToggle = () => {
    setToggle(!toggle);
  };
  useEffect(()=>{
    setToggle(false)
  },[orderCall])
  // console.log(orderCall);

  const data1 = {
    title: "What is Spot Trading?",
      rows: [
        {
          title: "Real-Time Market Transactions",
          content:
            "Spot trading is the buying and selling of financial assets at their current market price. It’s a straightforward and efficient way to trade. With our platform, you can:\n•	Access real-time prices and execute trades instantly.\n•	Trade a variety of assets, including stocks, commodities, and cryptocurrencies.\n•	Experience secure and seamless transactions backed by cutting-edge technology.",
        },
        {
          title: "Master Your Skills with Paper Trading",
          content: "Paper trading allows you to practice trading without financial risk. It’s perfect for beginners and those testing new strategies. Features include:\n•	A realistic trading environment to simulate live markets.\n•	The ability to refine your trading techniques with zero risk.\n•	Comprehensive tools to analyze performance and improve decision-making.\nStart paper trading today to build confidence and expertise.",
        },
        {
          title: "Explore Spot Currency Trading",
          content: "Dive into the exciting world of spot currency trading with our advanced tools and resources. The forex market offers unmatched liquidity and trading opportunities. With Crypto24x7, you can:\n•	Trade major, minor, and exotic currency pairs.\n•	Utilize robust charting and analysis tools.\n•	Benefit from competitive pricing and fast execution speeds.\nWhether you're trading for growth or hedging against market volatility, we’re here to support your success.",
        },
        {
          title: "Why Choose Us?",
          content: "•	User-Friendly Interface: Intuitive design for all skill levels.\n•	Comprehensive Education: Gain insights through tutorials, webinars, and expert tips.\n•	Reliable Support: Our team is available 24/7 to assist you.",
        },
        {
          title: "Start Trading Today",
          content: "Take the first step toward financial success with spot trading, paper trading, and spot currency trading. Sign up with Crypto24x7 and transform your trading experience",
        },
      ]
    }

    const faqData = {
      title: "FAQs About Spot Trading, Paper Trading, and Spot Currency Trading",
      rows: [
        {
          title: "What is spot trading in cryptocurrency?",
          content:
           'Spot trading refers to the purchase or sale of a cryptocurrency for immediate delivery, meaning transactions are settled "on the spot" at the current market price. This type of trading is direct and usually happens quickly.',
        },
        {
          title: "How does paper trading work?",
          content:
            "Paper trading is a simulation of real market trading without the use of real money. It allows you to practice trading strategies and gain experience before engaging in live trading, making it ideal for beginners.",
        },
        {
          title: "What is the difference between spot trading and paper trading?",
          content:
            "Spot trading involves real-money transactions at the current market price, while paper trading is a risk-free practice tool where you simulate trades without using real funds.",
        },
        {
          title: "What is spot currency trading?",
          content:
            "Spot currency trading (also known as forex trading) involves buying and selling currencies at their current market prices, with transactions typically settled within two business days. It is the most popular form of currency trading due to its simplicity and liquidity.",
        },
        {
          title: "Why is spot trading so popular?",
          content:
            "Spot trading is popular because of its simplicity, ease of execution, and the fact that transactions are settled quickly. It's ideal for traders who want to take advantage of immediate market prices without waiting for futures contracts or options to settle.",
        },
        {
          title:
            "Can I use paper trading to test spot trading strategies?",
          content:
            "Yes! Paper trading is a great way to test and refine your spot trading strategies without risking any real money. It provides a safe environment to practice buying and selling digital currencies.",
        },
        {
          title: "How do I start spot currency trading?",
          content:
            "To start spot currency trading, you need to choose a reliable platform like Crypto24x7, select the currency pairs you wish to trade, and then buy or sell based on current market conditions. Make sure to have a clear strategy in place before trading.",
        },
        {
          title: "What are the risks involved in spot currency trading?",
          content:
            "While spot currency trading offers high liquidity and the potential for profit, it also carries the risk of market volatility. Prices can fluctuate rapidly, so it's important to stay informed and manage risk carefully.",
        },
        {
          title: "Can I use leverage in spot currency trading?",
          content:
            "Yes, many platforms offer leverage in spot currency trading, allowing you to control a larger position with a smaller initial investment. However, leverage increases both potential gains and losses, so it should be used carefully.",
        },
        {
          title: "How do I transition from paper trading to real spot trading?",
          content:
            "Once you’re comfortable with paper trading and have tested your strategies, you can transition to real spot trading by opening a live account, depositing funds, and executing trades with real capital. Be sure to start small and gradually increase your exposure.",
        },
      ],
    };
  return (
    <>
      <HelmetBase
      title={"Spot Trading, Paper Trading, & Spot Currency Trading "}
      description={
       "Explore spot trading, paper trading, and spot currency trading with Crypto24x7. Learn, practice, and trade confidently with our user-friendly platform and expert resources."
      }
      keywords={'practice crypto trading, crypto trading demo account, 12. spot trading crypto, 13. spot trade means, 14. spot trading, spot, crypto, crypto24x7, cryptocurrency, crypto tracking, crypto chart, chart'}
      />
      <Navbar />
      {/* <br />
      <br /> */}
      {/* <div className="center stockscreen">
        <h1>Trading</h1>
        <br />

        <p style={{ textAlign: "center" }}>
          The Cryptop Token is the key to unlocking the full potential of
          Cryptop. Get even higher
          <br /> earning rates and our lowest borrowing rates.
        </p>
        <br />
      </div> */}
      {/* <br />
      <br /> */}
      <div style={{ display:"flex" ,justifyContent:"end"}}>
        <div className="stockrates-div" >
          <p className="stockrates-divp">Wallet</p>
          <p>{userData?.wallet?.currency}{" "}{Number(userData?.wallet?.balance||0).toFixed(2)}</p>
        </div>
      </div>
      {tvLode && <Ticker theme={theme} />}
      <br />
      {tvLode && <TradingPanel theme={theme} symbol={symbol} />}
      
      {isorderCall && <BuySell />}
      <br />
      <br />
      <div className="order-accodian">
        <div className="order-accodian-div">
          <div className="order-accodian-div-2" onClick={handleToggle}>
            <p style={{color: 'black'}}>Order Mangement</p>
            <img src={arrow} onClick={handleToggle} />
          </div>
          <div>{toggle && <PaperOrder isCalling={toggle} />}</div>
        </div>
      </div>
      <br />
      <h1 style={{textAlign:'center'}}>Unlock the Power of Trading with Crypto24x7</h1>
      <p style={{textAlign:'center',maxWidth: 1400, margin: 'auto'}}>Welcome to Crypto24x7, your trusted partner in spot trading, paper trading, and spot currency trading. Whether you're an experienced trader or taking your first steps into the market, we provide the tools and resources you need to succeed.</p>
        <br />
        <Content data={data1}/>
        <br />
        <Faq data={faqData} />
      <Footer />
      <AddJsonLdScript data={{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is spot trading in cryptocurrency?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Spot trading refers to the purchase or sale of a cryptocurrency for immediate delivery, meaning transactions are settled \"on the spot\" at the current market price. This type of trading is direct and usually happens quickly."
        }
      },{
        "@type": "Question",
        "name": "How does paper trading work?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Paper trading is a simulation of real market trading without the use of real money. It allows you to practice trading strategies and gain experience before engaging in live trading, making it ideal for beginners."
        }
      },{
        "@type": "Question",
        "name": "What is the difference between spot trading and paper trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Spot trading involves real-money transactions at the current market price, while paper trading is a risk-free practice tool where you simulate trades without using real funds."
        }
      },{
        "@type": "Question",
        "name": "What is spot currency trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Spot currency trading (also known as forex trading) involves buying and selling currencies at their current market prices, with transactions typically settled within two business days. It is the most popular form of currency trading due to its simplicity and liquidity."
        }
      },{
        "@type": "Question",
        "name": "Why is spot trading so popular?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Spot trading is popular because of its simplicity, ease of execution, and the fact that transactions are settled quickly. It's ideal for traders who want to take advantage of immediate market prices without waiting for futures contracts or options to settle."
        }
      },{
        "@type": "Question",
        "name": "Can I use paper trading to test spot trading strategies?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes! Paper trading is a great way to test and refine your spot trading strategies without risking any real money. It provides a safe environment to practice buying and selling digital currencies."
        }
      },{
        "@type": "Question",
        "name": "How do I start spot currency trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "To start spot currency trading, you need to choose a reliable platform like Crypto24x7, select the currency pairs you wish to trade, and then buy or sell based on current market conditions. Make sure to have a clear strategy in place before trading."
        }
      },{
        "@type": "Question",
        "name": "What are the risks involved in spot currency trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "While spot currency trading offers high liquidity and the potential for profit, it also carries the risk of market volatility. Prices can fluctuate rapidly, so it's important to stay informed and manage risk carefully."
        }
      },{
        "@type": "Question",
        "name": "Can I use leverage in spot currency trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, many platforms offer leverage in spot currency trading, allowing you to control a larger position with a smaller initial investment. However, leverage increases both potential gains and losses, so it should be used carefully."
        }
      },{
        "@type": "Question",
        "name": "How do I transition from paper trading to real spot trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Once you’re comfortable with paper trading and have tested your strategies, you can transition to real spot trading by opening a live account, depositing funds, and executing trades with real capital. Be sure to start small and gradually increase your exposure."
        }
      }]
    }} />
    </>
  );
}

export default Trading;
