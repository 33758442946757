import React from 'react'
import { BsEmojiFrown } from "react-icons/bs";
import { Link } from 'react-router-dom';
import HelmetBase from '../helmet/HelmetBase';

export default function NotFound() {

  return (
    <>
    <HelmetBase noIndex={true} />
   
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column', height: '100vh', gap: 10}}>
    <BsEmojiFrown style={{fontSize: 100}}/>
    <span style={{fontSize: 22, fontWeight: 'bold'}}>Oops... page not found.</span>
    <p style={{fontWeight: 'bolder', textAlign:'center'}}>We don't know how you ended up here, but you should go away now.</p>
    <Link
    to={'/'}
    style={{
        backgroundColor: 'blue',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        textDecoration: 'none',
        display: 'inline-block',
        textAlign: 'center'
    }}
>
    Home Page
</Link>

</div>
</>
  )
}
