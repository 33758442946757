import axios from "axios";
import { headers, jwtHeaders } from "../common/data";
import { scannerBaseUrl as baseUrl } from "../../utils/binanceurl";

export const generateScanner = async(data)=>{
    console.log(data);
    try {
        console.log('res');
        const res = await axios.post(`${baseUrl}/scanner/generate`, data)
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
} 

export const saveScanner = async(data)=>{
    try {
        const res = await axios.post(`${baseUrl}/userscan/scanners`, data, {headers:jwtHeaders()})
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
} 

export const deleteScan = async(dashboardId, scannerId)=>{
    try {
        const res = await axios.delete(`${baseUrl}/userscan/scanners/${dashboardId}/${scannerId}`, {headers:jwtHeaders()})
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
} 

export const getAllScan = async()=>{
    try {

        const res = await axios.get(`${baseUrl}/userscan/scanners`, {headers:jwtHeaders()})
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
} 

export const getSingleScan = async(id)=>{
    try {
        const res = await axios.get(`${baseUrl}/userscan/scanners/${id}`, {headers:jwtHeaders()})
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
} 

export const updateScan = async(id, data)=>{
    try {
        const res = await axios.put(`${baseUrl}/userscan/scanners/${id}`, data, {headers:jwtHeaders()})
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
} 

export const getAllScreeners = async(id=null, name=null, category=null, limit=null, page=null) => {
    try {
        let API = `${baseUrl}/userscan/scannersall`;

        const query = new URLSearchParams();

        if (id) query.append("id", id);
        if (name) query.append("name", name);
        if (category) query.append("category", category);
        if (limit) query.append("limit", limit);
        if (page) query.append("page", page);

        if(id || name || category || limit || page){
            API+= `?${query.toString()}`
        }

        const res = await axios.get(API);
        console.log(res);
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}


