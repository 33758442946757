import React, { useEffect, useState } from "react";
import { useMain } from "../../hooks/useMain";
import filter from "../../Assets/Vector (11).png";

const FundTable = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
  const { getFundData } = useMain();

  const handleGet = async () => {
    try {
      const res = await getFundData(page);
      setTotalPages(res?.data?.list?.total_pages);
      setData(res?.data?.list?.results);
    } catch (error) {
      console.error("Error fetching fund data", error);
    }
  };

  useEffect(() => {
    handleGet();
  }, [page]);

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };


  const handleSort = (key) => {
    // console.log(key);
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data].sort((a, b) => {
    console.log(a[sortConfig.key],  b[sortConfig.key], sortConfig);
    // key == fund_type.name 
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });


  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return '↑↓';
    }
    return sortConfig.direction === 'ascending' ? '↑' : '↓';
  };

  // const startIndex = (page - 1) * itemsPerPage;
  // const displayedData = sortedData.slice(startIndex, startIndex + itemsPerPage);


  return (
    <>
      <div className="assets-btns">
        <div className="assets-btns4">
          <button> Funds</button>
          <button>Top ROI</button>
          <button>Top Gainers</button>
          <button>Trending</button>
          <button>Heat Map</button>
          <button>Most Active</button>
        </div>
        <div className="assets-btns4">
          <button>
            <img src={filter} />
          </button>
        </div>
      </div>

      <div className="stock-passesall-div">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col"  onClick={() => handleSort('name')}>Name {getSortIcon('name')}</th>
              <th scope="col"  >Projects</th>
              <th scope="col"  onClick={() => handleSort('project_count')}># of Projects {getSortIcon('project_count')}</th>
              <th scope="col"  onClick={() => handleSort('average_current_roi')}>ROI {getSortIcon('average_current_roi')}</th>
              <th scope="col"  >Type</th>
              <th scope="col"  onClick={() => handleSort('last_investment')}>Last Investment {getSortIcon('last_investment')}</th>
              <th scope="col"  onClick={() => handleSort('status')}>Status {getSortIcon('status')}</th>
              <th scope="col"  onClick={() => handleSort('average_private_raise')}>Private Raise {getSortIcon('average_private_raise')}</th>
              <th scope="col"  onClick={() => handleSort('average_total_raise')}>Total Raise {getSortIcon('average_total_raise')}</th>
              <th scope="col"  onClick={() => handleSort('average_price_change_24h')}>Change 24H {getSortIcon('average_price_change_24h')}</th>
            </tr>
          </thead>
          <tbody>
            {sortedData && sortedData.length>0 && sortedData.map((fund, index) => (
              <tr key={index}>
                <td>
                  <img
                    src={fund.logo}
                    alt={fund.logo_alt}
                    title={fund.logo_title}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                  />
                  {fund.name}
                </td>
                <td>
                  {fund.projects.map((project, idx) => (
                    <img
                      key={idx}
                      src={project.logo}
                      alt={project.logo_alt}
                      title={project.logo_title}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  ))}
                </td>
                <td >{fund.project_count}</td>
                <td>{fund.average_current_roi}</td>
                <td>{fund.fund_type.name}</td>
                <td>{fund.last_investment}</td>
                <td>{fund.status}</td>
                <td>{fund.average_private_raise}</td>
                <td>{fund.average_total_raise}</td>
                <td>{fund.average_price_change_24h}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={handlePreviousPage} disabled={page === 1}>
            Previous
          </button>
          <span>
            Page {page} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={page === totalPages}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default FundTable;
