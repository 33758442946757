import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import searchicon from '../Assets/Search.svg'
import HelmetBase from '../helmet/HelmetBase'


function Stacking2() {
  return (
    <>
     <HelmetBase noIndex={true} />
        <Navbar/>
        <br/>
    <br/>
    <div className='center stockscreen'>
      <h1>Stacking</h1>
    <br/>

      <p style={{textAlign:'center'}}>The Cryptop Token is the key to unlocking the full potential of Cryptop. Get even higher earning rates and our lowest borrowing rates.</p>
      <br/>
   
   <div className='searchtab'>
    <input placeholder='Search' />
    <img src={searchicon}/>

   </div>
   <br/>

<div className='stackbtn'>
    <button className='Launchpad'>Launchpad</button>
    <button>Launchpad</button>

</div>
<br/>
 
    </div>
    <br/>
        <Footer/>
    </>
  )
}

export default Stacking2