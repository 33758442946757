import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import dash from "../Assets/Group 1000005966.png";
import dash2 from "../Assets/Add a subheading2.png";
import dash3 from "../Assets/Group 1000005965.png";
import dash4 from "../Assets/Group 1000005967.png";
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import HelmetBase from '../helmet/HelmetBase';
import { Helmet } from 'react-helmet-async';

function NewSlide() {
  return (
    <>
    {/* <Helmet>
    <link rel="preload" as="image" href={dash} type="image/png" />
    <link rel="preload" as="image" href={dash2} type="image/png" />
    <link rel="preload" as="image" href={dash3} type="image/png" />
    <link rel="preload" as="image" href={dash4} type="image/png" />
    </Helmet> */}
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
    //   navigation={true}
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide style={{backgroundColor: 'inherit'}}>  
       <div>
          <div className="expscreen expscreen2">
            <div className="expscreen-left">
              <h1 style={{fontSize: 36, fontWeight: 700}}>Learn Crypto Trading with Crypto 24x7</h1>
              <br />
              <p style={{ textAlign: "justify" }}>
              Welcome to your ultimate resource for understanding cryptocurrency trading and investing. Whether you're new to the world of digital assets or looking to sharpen your skills, we’re here to guide you every step of the way.
              </p>
              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
            <div className="expscreen-right">
              <img src={dash} alt="Dashboard" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide style={{backgroundColor: 'inherit'}}>  
       <div>
          <div className="expscreen expscreen2">
            <div className="expscreen-right">
              <img src={dash2} alt="Dashboard" />
            </div>
            <div className="expscreen-left">
              <h2 style={{fontSize: 36, fontWeight: 700}}>Discover the Best Crypto Screeners</h2>
              <br />
              <p style={{ textAlign: "justify" }}>
              Finding the right cryptocurrency to trade or invest in requires the right tools. A crypto screener helps you analyze market trends, price changes, and trading volumes. Explore our curated list of the best crypto screeners to streamline your decision-making process.
              </p>
              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide style={{backgroundColor: 'inherit'}}>
      <div>
          <div className="expscreen expscreen2">
            <div className="expscreen-left">
              <h3 style={{fontSize: 36, fontWeight: 700}}>Explore Funds</h3>
              <br />
              <p style={{ textAlign: "justify" }}>
                Unlock a world of investment opportunities with our expertly
                curated funds. Whether you're seeking growth, stability, or
                diversification, our selection offers something for every
                investor.
              </p>
              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
            <div className="expscreen-right">
              <img src={dash3} alt="Dashboard" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide style={{backgroundColor: 'inherit'}}>
      <div>
          <div className="expscreen">
          <div className="expscreen-right">
              <img src={dash} alt="Dashboard" />
            </div>
            <div className="expscreen-left">
              <h4 style={{fontSize: 36, fontWeight: 700}}>Explore Trading</h4>
              <br />
              <p style={{ textAlign: "justify" }}>
                Step into the world of trading with tools and strategies
                designed for success. Whether you're a seasoned trader or just
                starting out, our platform provides the insights and resources
                you need to navigate the markets with confidence.
              </p>
              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide style={{backgroundColor: 'inherit'}}>
      <div>
          <div className="expscreen expscreen2">
            <div className="expscreen-left">
              <h5 style={{fontSize: 36, fontWeight: 700}}>Explore Initial Offerings</h5>
              <br />
              <p style={{ textAlign: "justify" }}>
                Discover groundbreaking opportunities with our exclusive access
                to initial offerings. Be among the first to invest in innovative
                projects and emerging markets, positioning yourself at the
                forefront of the next big trend.
              </p>

              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
            <div className="expscreen-right">
              <img src={dash4} alt="Dashboard" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide style={{backgroundColor: 'inherit'}}>
      <div>
          <div className="expscreen expscreen2">
            <div className="expscreen-right">
              <img src={dash3} alt="Dashboard" />
            </div>
            <div className="expscreen-left">
              <h6 style={{fontSize: 36, fontWeight: 700}}>Learning About Crypto Investing</h6>
              <br />
              <p style={{ textAlign: "justify" }}>
              Investing in cryptocurrencies can seem complex, but with the right tools and strategies, it can be a rewarding experience. Our comprehensive guides cover the essentials of crypto investing, from selecting the best coins to managing your portfolio effectively.
              </p>

              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide style={{backgroundColor: 'inherit'}}>
      <div>
          <div className="expscreen expscreen2">
            <div className="expscreen-left">
              <h6 style={{fontSize: 36, fontWeight: 700}}>Spot Trading Crypto: Simple Yet Powerful</h6>
              <br />
              <p style={{ textAlign: "justify" }}>
              Spot trading is a straightforward way to buy and sell cryptocurrencies. Learn how to navigate spot trading platforms, execute trades, and take advantage of market movements. Our step-by-step tutorials make it easy for beginners to get started.
              </p>

              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
            <div className="expscreen-right">
              <img src={dash2} alt="Dashboard" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide style={{backgroundColor: 'inherit'}}>
      <div>
          <div className="expscreen expscreen2">
            <div className="expscreen-right">
              <img src={dash4} alt="Dashboard" />
            </div>
            <div className="expscreen-left">
              <h5 style={{fontSize: 36, fontWeight: 700}}>Cryptocurrency Option Trading: Advanced Strategies</h5>
              <br />
              <p style={{ textAlign: "justify" }}>
              For those seeking advanced strategies, cryptocurrency option trading offers opportunities to hedge risks and maximize returns. Understand how options work, the types of contracts available, and how to develop a winning trading strategy.
              </p>

              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
          </div>
        </div>
      </SwiperSlide>

    </Swiper>
  </>
  )
}

export default NewSlide