import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMain } from "../hooks/useMain";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import HelmetBase from "../helmet/HelmetBase";

export default function PublicScreenersByCategory() {
  const navigate = useNavigate();
  const { category } = useParams();
  const { getAllScreeners } = useMain();
  const [allScreeners, setAllScreeners] = useState([]);
  const [num, setNum] = useState(10);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    categoryHandler(page);
  }, [category]);

  const categoryHandler = async (p) => {
    try {
      const result = await getAllScreeners(null, null, category, limit, p);
      if (result?.data) {
        setAllScreeners(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    <HelmetBase noIndex={true} />
    <Navbar/>
    <div className="public-screeners-list">
      <div className="scr-card-list card">
        <h3>{category.replaceAll("_", " ")}</h3>
        <div className="p-s-list">
          {allScreeners.map((scraper, index) => (
            <p
              className="print-list"
              style={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
                padding: "8px",
                marginBottom: 0,
                cursor: "pointer",
              }}
              key={index}
              onClick={() => {
                navigate(`/public-screeners/${scraper._id}`);
              }}
            >
              {scraper.name}
            </p>
          ))}
        </div>
       <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <span>{page}</span>
       <button
          className="more-btn"
          style={{
            float: "right",
            margin: "10px 0px 5px 0px",
            padding: "5px 20px",
            border: "none",
          }}
          onClick={() => {
            if(allScreeners.length >= limit){
              setPage((prevPage) => prevPage + 1);
              categoryHandler( page+1);
            }else{
              categoryHandler( page);
            }
           
          }}
        >
          More
        </button>
       </div>
      </div>
    </div>
    <Footer/>
    </>
  );
}
