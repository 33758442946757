import React, { useState } from 'react';
import ChatBot from 'react-simple-chatbot';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Import axios for sending data
import { useMain } from '../hooks/useMain';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

const steps = [
  {
    id: '1',
    message: 'Hello! How can I assist you today?',
    trigger: '2',
  },
  {
    id: '2',
    options: [
      { value: 'help', label: 'I need help', trigger: '3' },
      { value: 'feedback', label: 'Give feedback', trigger: '8' },
      { value: 'other', label: 'Other', trigger: '5' },
    ],
  },
  {
    id: '3',
    message: 'Sure, I am here to help! What do you need help with?',
    trigger: '6',
  },
  {
    id: '4',
    message: 'Thank you for your feedback! Is there anything else you’d like to share?',
    trigger: '6',
  },
  {
    id: '5',
    message: 'Please type your message below and I will try to assist you.',
    trigger: '6',
  },
  {
    id: '6',
    user: true,
    trigger: '7',
  },
  {
    id: '7',
    message: 'Thank you for your message. I’ll get back to you as soon as possible.',
    end: true,
  },
  {
    id: '8',
    user: true,
    trigger: '4',
  },
];

const ChatBotComponent = () => {
  const [show, setShow] = useState(false);
  const [chatData, setChatData] = useState(null);
  const { chatSend,userData } = useMain()

  const handleClose = () => {
    // Capture chat data before closing
    if (chatData) {
      sendChatData(chatData);
      handleMessage(chatData);
    }
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleEnd = ({ steps, values }) => {
    const chatData = { steps, values };
    setChatData(chatData); // Store chat data in state
    console.log(chatData); // Debug: Log data to console
  };
  const formatMessageForEmail = (steps) => {
    // console.log(steps);
    let message = "Chat Summary:\n\n";
    steps.forEach((step) => {
      if(step.value){
        message += `User: ${step.value}\n`;
      }
      else{
        message += `Bot: ${step.message}\n`;
      } 
      
    });
    return message;
  };

  const sendChatData = async(chatData) => {
    const formattedMessage = formatMessageForEmail(chatData.steps);
    console.log(formattedMessage);
   await chatSend({chat:formattedMessage})
  };

  const handleMessage =async (chatData) => {
    const YOUR_PUBLIC_KEY = "Sak8hi4DBKTZUHZvk"
    const YOUR_SERVICE_ID = "service_rcjlwuf"
    const YOUR_TEMPLATE_ID = "template_04w6ehr"

    const formattedMessage = formatMessageForEmail(chatData.steps);

    let userName = ''
    if(userData?.firstName){
      userName = userData?.firstName+''+userData?.lastName
    }
    const formData = {
       user_name: userName,
       user_email: userData?.email,
       user_phone: userData?.mobile,
       user_message: formattedMessage
   };
 
   emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, formData, { publicKey: YOUR_PUBLIC_KEY })
   .then(
     (response) => {
      //  console.log('SUCCESS!', response.status, response.text);
       toast.success('Thank you for your message. '+response.text)
     },
     (err) => {
       console.log('FAILED...', err);
     },
   );
   
   };

  return (
    <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
      <Button 
        variant="primary" 
        style={{ borderRadius: '50%', width: '50px', height: '50px', fontSize: '24px' }}
        onMouseOver={handleShow}
      >
        {show ? '-' : '+'}
      </Button>

      <Modal show={show} onHide={handleClose} centered style={{ width: '400px', margin: 'auto' }}>
        <Modal.Header closeButton style={{ width: '100%' }}>
          <Modal.Title>Chatbot</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: '100%' }}>
          <ChatBot steps={steps} handleEnd={handleEnd} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChatBotComponent;
