import { useEffect } from 'react'

export default function AddJsonLdScript({data}) {
  useEffect(() => {
     const script = document.createElement("script");
     script.type = "application/ld+json";
     script.innerHTML = JSON.stringify(data);
     document.body.appendChild(script);
 
     // Cleanup function to remove the script when the component unmounts
     return () => {
       document.body.removeChild(script);
     };
   }, []);
 
   return null; // No visible UI, just adds the script
}
