import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from 'react-router-dom';

const HelmetBase = ({
  title = "Learn Crypto Trading & Investing - Master Crypto with Expert Guides & Tools",
  description = "Discover everything you need to know about cryptocurrency trading and investing. Access beginner guides, advanced strategies, crypto screeners, and expert resources to start your journey in the crypto world today.",
  keywords = "crypto24x7 24x7 learn crypto trading, cryptocurrency investing, crypto trading strategies, crypto screeners, spot trading crypto, cryptocurrency options, cryptocurrency trading tools, crypto investment guides, digital asset trading, crypto market analysis",
  noIndex = false,
}) => {
  const location = useLocation();
  const canonicalUrl = `https://crypto24x7.io${location.pathname}`;
  const passSchema = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: title,
    description: description,
  };
  // alert(showAdd);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="robots" content={noIndex ? "noindex, nofollow" : "index, follow"} />
        <script type="application/ld+json">{JSON.stringify(passSchema)}</script>
        <link rel="canonical" href={canonicalUrl} />

        {/* {noIndex ? <meta name="robots" content="noindex, nofollow" /> : <meta name="robots" content="index, follow" /> } */}
      </Helmet>
    </>
  );
};
export default HelmetBase;
