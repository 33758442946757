import axios from "axios";
import { headers, jwtHeaders } from "../common/data";
import { userBaseUrl as baseUrl } from "../../utils/binanceurl";
import { toast } from "react-toastify";

export const createuser = async(data)=>{
    console.log(data);
    try {
        console.log('res');
        const res = await axios.post(`${baseUrl}/user/create`, data)
        console.log(res);
        if(res?.status==200){
            return res.data
        }
        if(res?.status==400){
            toast.error(res.message)
        }
    } catch (error) {
        console.log(error.message);
    }
} 

export const verifySignup = async(data)=>{
    try {
        const res = await axios.post(`${baseUrl}/user/create/verify`, data)
        console.log(res);
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
} 

export const loginuser = async(data)=>{
    try {
        const res = await axios.post(`${baseUrl}/user/login`, data)
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
} 

export const verifylogin = async(data)=>{
    try {
        const res = await axios.post(`${baseUrl}/user/login/verify`, data)
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
} 


export const sendOtp = async (apiKey, phoneNumber, otp) => {
    const url = `https://2factor.in/API/V1/${apiKey}/SMS/${phoneNumber}/AUTOGEN/${otp}`;
    try {
        const res = await axios.get(url);
        if (res?.status == 200) {
            return res.data;
        }
    } catch (error) {
        console.log(error.message);
    }
};

// Function to verify OTP
export const verifyOtp = async (apiKey, otpSessionId, otpEnteredByUser) => {
    const url = `https://2factor.in/API/V1/${apiKey}/SMS/VERIFY/${otpSessionId}/${otpEnteredByUser}`;
    try {
        const res = await axios.get(url);
        if (res?.status == 200) {
            return res.data;
        }
    } catch (error) {
        console.log(error.message);
    }
};

