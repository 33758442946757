import React, { useEffect, useState } from "react";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import LinkScannerDetail from "./Dashboard/LinkScannerDetail";
import HelmetBase from "../helmet/HelmetBase";

function LinkDashBoardDetail() {
  return (
    <>
     <HelmetBase noIndex={true} />
      <Navbar />
      {/* <br />
      <br />
      <br />
      <br /> */}
      <LinkScannerDetail />
      <br />
      <br />

      <br />
      <br />
      <br />
      <br />

      <br />
      <br />

      <Footer />
    </>
  );
}

export default LinkDashBoardDetail;
