import React from 'react'
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function SwitchButton({label, action, checked}) {
  return (
        <Form>
            <Form.Check
            type='switch'
            id='custom-switch'
            label={label}
            checked={checked}
            onClick={action}
            />
        </Form>
  )
}
