import React, { useEffect, useState } from "react";
import img from "./Assets/image 2 (1).svg";
import google from "./Assets/Google.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMain } from "./hooks/useMain";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import parsePhoneNumberFromString from "libphonenumber-js";
import { GetCountries } from "react-country-state-city";
import logo from './Assets/newcoin.png'
import HelmetBase from "./helmet/HelmetBase";

function Login() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [emailOrNumber, setEmailOrNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countriesList, setCountriesList] = useState([]);

  const [errors, setErrors] = useState({
    emailOrNumber: "",
    otp: "",
    countryCode: "",
  });

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  const { loginuser, verifylogin, handleChangeLoggin,googleLogin } = useMain();
  const navigate = useNavigate();

  const validatePhoneNumber = (number, countryCode) => {
    const finalNumber = "+" + countryCode + number;
    const phoneNumber = parsePhoneNumberFromString(finalNumber);
    return phoneNumber && phoneNumber.isValid();
  };
  

  const handleLogin = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!emailOrNumber) {
      newErrors.emailOrNumber = "Phone Number is required.";
    }  else if (!validatePhoneNumber(emailOrNumber, countryCode)) {
      newErrors.emailOrNumber = "Invalid phone number.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setLoader(true);

    const payload = {
      mobile: emailOrNumber,
      countryCode: countryCode,
    };

    try {
      const res = await loginuser(payload);
      if (res.data) {

        setLoader(false);
        setIsOtpSent(true);
        toast.success("OTP sent to your phone.");
      }
    } catch (error) {
      setLoader(false);
      toast.error("Login failed.");
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!otp) {
      newErrors.otp = "OTP is required.";
    }
    if(otp.length != 6){
      newErrors.otp = "OTP Must Be 6 Digit.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setLoader(true);

    const payload = {
      mobile: emailOrNumber,
      otp,
      countryCode,
    };

    try {
      const res = await verifylogin(payload);
      if (res.data) {
        sessionStorage.setItem('user_token', res?.data?.token)
        localStorage.removeItem("wishlist")
        setLoader(false);
        toast.success("Login successful!");
        handleChangeLoggin(true)
        if(searchParams.get('ref') === 'dashboard'){
          navigate("/dashboard");
        }
        else if (searchParams.get('ref') === 'spot'){
          navigate("/spot");
        }
        else{
          navigate("/");
        }
      }
     

    } catch (error) {
      setLoader(false);
      toast.error("OTP verification failed.");
    }
  };

  const responseGoogleSuccess = async (data) => {
    console.log(data);
    try {
      const decode = jwtDecode(data?.credential);
      console.log(decode);
      if(decode?.email_verified==false){
        return toast.error("Email not verified.")
      }
      const { name, email, picture } = decode;
      const res = await googleLogin( { name, email, picture })
      if(res?.data){
        console.log(res);
        sessionStorage.setItem('user_token', res?.data?.token)
        localStorage.removeItem("wishlist")
        toast.success("Login Success")
        
      if(searchParams.get('ref') == 'dashboard'){
        navigate("/dashboard");
      }
      else if (searchParams.get('ref') == 'spot'){
        navigate("/spot");
      }
      else{
        navigate("/");
      }
    }
    } catch (error) {
      
    }
  };

  const responseGoogleError = (response) => {
    console.log(response);
    toast.error("Google login failed.");
  };

  return (
    <>
    <HelmetBase
          title={"Secure Login to Your Crypto24x7 Crypto Account"}
          description={
            "Log in to your Crypto24x7 account to access your crypto holdings, manage your investments, and start trading!"
          }
        />
      <div className="login">
        <div className="login-left">
        <Link to='/'>
        <img className="sing-logo" src={logo}/>
        </Link>
          <div className="login-left-center">
            <img src={img} alt="Login Illustration" />
          </div>
        </div>
        <div className="login-right">
          <Link to="/signup">
            <button className="signup-btn">Sign Up</button>
          </Link>
          <div className="login-form">
            <h1>Login</h1>
            <br />
            <br />
            <form onSubmit={isOtpSent ? handleVerifyOtp : handleLogin}>
              {!isOtpSent && (
                <>
                 <select
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  >
                    <option value="">Select Country Code</option>
                    {countriesList.map((country) => (
                      <option key={country.isoCode} value={country.phone_code}>
                        {country.name} (+{country.phone_code})
                      </option>
                    ))}
                  </select>
                  <input
                    placeholder="Enter Number without country code"
                    value={emailOrNumber}
                    onChange={(e) => setEmailOrNumber(e.target.value)}
                  />
                  {errors.emailOrNumber && <p className="error">{errors.emailOrNumber}</p>}
                  <br />
                </>
              )}
              {isOtpSent && (
                <>
                  <input
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) =>setOtp(e.target.value.replace(/\D/g, ''))}
                  />
                  {errors.otp && <p className="error">{errors.otp}</p>}
                </>
              )}
              <br />
              <br />
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Or
              </p>
              <br />
             <div className="google-btn">
             <GoogleLogin 
                onSuccess={responseGoogleSuccess}
                onError={responseGoogleError}
              />
             </div>
              {/* <button type="button" className="logwithgoogle">
                <img src={google} alt="Google Icon" />
                Login With Google
              </button> */}
              <br />
              <div className="center">
                <button type="submit" className="logbtn">
                  {isOtpSent ? "Verify OTP" : "Log In"}
                </button>
              </div>
            </form>
            <br />
            <br />
            <p>
              Haven’t got an account yet?{" "}
              <Link to="/signup">
                <span>Sign Up</span>
              </Link>{" "}
              now
            </p>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </>
  );
}

export default Login;
