import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import logo from '../Assets/image 201.png'
import tool from '../Assets/tools-svgrepo-com 4.png'
import HelmetBase from '../helmet/HelmetBase'

function AirDropDetail() {
  return (
   <>
    <HelmetBase noIndex={true} />
    <Navbar/>
    <div className='AirDropDetail-icon'>
    <img src={logo}/>

    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

    <div className='airdrop-setting'>



     <div className='airdrop-setting-div'>
     <p><img src={tool}/>Actions: Collect Points</p>
      <p><img src={tool}/>Actions: Collect Points</p>
      <p><img src={tool}/>Actions: Collect Points</p>

      <p><img src={tool}/>Actions: Collect Points</p>
      <p><img src={tool}/>Actions: Collect Points</p>

     </div>
    </div>
    <br/>
    <br/>
    <div className='airdrop-text-div'>
    <div className='airdrop-text'>
<p>DTX is a decentralized perpetual exchange built with Blast native yield, supporting 100+ crypto markets with up to 100x leverage. Unlike order book systems, DTX's unique synthetic architecture guarantees a seamless trading experience for traders by eliminating slippage and ensuring guaranteed order execution. Additionally, it offers flexibility in collateral usage and market-making while simultaneously maximizing capital efficiency for LPs.
DTX has launched a points campaign called “DTX FUEL” and confirmed that it will airdrop DTX tokens to users who collect Fuel. Create an account at DTX and make trades to earn Fuel. You’ll also get 0.25% FUEL from each referral. The Fuel will be converted to DTX tokens in the future. You will also get Blast points and 100% of the Blast Gold airdrop for trading on DTX.</p>
<br/>
<button>Apply Now</button>
</div>
    </div>
    <br/>
    <br/>
    <Footer/>
   </>
  )
}

export default AirDropDetail