import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

export default function Faq({ data }) {
  const [activeIndex, setActiveIndex] = useState(null);

  // const faqData = {
  //   title: "FAQs About Crypto Screeners and Tools",
  //   rows: [
  //     {
  //       title: "How can I effectively track my crypto investments?",
  //       content:
  //         "Use apps like Binance or Blockfolio for seamless portfolio tracking.",
  //     },
  //     {
  //       title: "What are the key indicators to watch in crypto trading?",
  //       content: "Monitor market cap, trading volume, and price trends.",
  //     },
  //   ]
  // }

  const toggleHandler = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div
      style={{
       
        maxWidth: '1400px',
        margin: '20px auto',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <h2
        style={{
          textAlign: 'center',
          fontSize: '28px',
          marginBottom: '20px',
          letterSpacing: '1px', // Adjusts spacing between characters
          wordSpacing: '2px', // Adjusts spacing between words
        }}
      >
        {data.title}
      </h2>
      {data.rows.map((row, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <div
            onClick={() => toggleHandler(index)}
            role="button"
            aria-expanded={activeIndex === index}
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
              fontSize: '20px',
              fontWeight: 'bold',
              padding: '10px',
              borderRadius: '5px',
              letterSpacing: '0.5px', // Slight spacing between characters
              wordSpacing: '1px', // Slight spacing between words
            }}
          >
            <h2 style={{ fontSize: '20px', margin: 0 }}>{row.title}</h2>
            {activeIndex === index ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          <div
            style={{
              maxHeight: activeIndex === index ? '900px' : '0',
              overflow: 'hidden',
              transition: 'max-height 0.3s ease',
              backgroundColor: 'transparent',
              padding: '0px 10px',
              fontSize: '16px',
              lineHeight: '1.8', // Adjusts line spacing
              borderRadius: '5px',
              letterSpacing: '0.3px', // Subtle character spacing
              wordSpacing: '1.5px', // Word spacing for better readability
            }}
          >
            <p style={{ margin: activeIndex === index ? '10px 0' : '0' }}>
              {row.content}
            </p>
          </div>
          <hr style={{ margin: '10px 0' }} />
        </div>
      ))}
    </div>
  );
}
