import React, { useEffect, useState } from "react";
import searchicon from "../Assets/Search.svg";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useMain } from "../hooks/useMain";
import { useNavigate } from "react-router-dom";

export default function PublicScreeners() {
  const navigate = useNavigate();
  const { getAllScreeners } = useMain();
  const [allScreeners, setAllScreeners] = useState({});
  const [Screeners, setScreeners] = useState([]);
  const [name, setName] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setScreeners([]);
    getAllScreenersHandler();
  }, []);

  const getAllScreenersHandler = async () => {
    try {
      const result = await getAllScreeners();
      if (result?.data) {
        const datas = result?.data;

        const groupedData = datas.reduce((acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          return acc;
        }, {});
        setAllScreeners(groupedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const screenersSearchHandler = async (searchName, p) => {
    try {
      const result = await getAllScreeners(null, searchName, null, limit, p);
      if (result?.data) {
        if (searchName !== "") {
          setScreeners(result?.data);
        } else {
          setScreeners([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar />

      <div className="public-sca">
        <h4>Search screeners</h4>
        <p>
          Search over <b>150,000+</b> scans or create your own customized
          screener.
        </p>
        <div
          className="input-group"
          style={{ display: "flex", justifyContent: "center", gap: "10px" }}
        >
          <input
            type="text"
            className="screeners-input"
            placeholder="Search screeners"
            onChange={(e) => {
              setName(e.target.value);
              screenersSearchHandler(e.target.value, page);
            }}
          />

          <button className="search-btn">
            <img
              src={searchicon}
              alt="search icon"
              style={{ cursor: "pointer", marginRight: 8 }}
              height={20}
            />
            Search
          </button>

          {Screeners.length > 0 && name !== "" && (
            <div className="public-screeners-list">
              <div className="scr-card-list card">
                <div className="p-s-list">
                  {Screeners.map((scraper, index) => (
                    <p
                      className="print-list"
                      style={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
                        padding: "8px",
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                      key={index}
                      onClick={() => {
                        navigate(`/public-screeners/${scraper._id}`);
                      }}
                    >
                      {scraper.name}
                    </p>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{page}</span>
                  <button
                    className="more-btn"
                    style={{
                      float: "right",
                      margin: "10px 0px 5px 0px",
                      padding: "5px 20px",
                      border: "none",
                    }}
                    onClick={() => {
                      if (Screeners.length >= limit) {
                        setPage((prevPage) => prevPage + 1);
                        screenersSearchHandler(name, page + 1);
                      } else {
                        screenersSearchHandler(name, page);
                      }
                    }}
                  >
                    More
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="public-screeners-list">
        {/* List of public screeners */}
        {/* <div className="scr-card-list card">
         <h3>Free Crypto Screeners</h3>
         <div className="p-s-list">
          {
            Screeners.map((scraper, index) => (
              <p 
              className="print-list" 
              style={{borderBottom: '1px solid rgba(0, 0, 0, 0.25)', padding: '8px', marginBottom: 0, cursor:'pointer'}} 
              key={index}
              onClick={()=>{navigate(`/public-screeners/${scraper._id}`)}}
              >
                {scraper.name}
              </p>
            ))
          }
         </div>
         <button className="more-btn" style={{float:'right', margin:'10px 0px 5px 0px', padding:'5px 20px',border:'none'}} onClick={MoreHandler}>More</button>
       </div> */}
        {Object.entries(allScreeners).map(([category, items]) => (
          <div className="screener-list card" key={category}>
            <h3>{category.replaceAll("_", " ")}</h3>
            <div className="p-s-list">
              {items.slice(0, 12).map((item) => (
                <p
                  className="print-list"
                  key={item._id}
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
                    padding: "8px",
                    marginBottom: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/public-screeners/${item._id}`);
                  }}
                >
                  {item.name}
                </p>
              ))}
            </div>
            <button
              style={{
                float: "right",
                margin: "10px 0px 5px 0px",
                padding: "5px 20px",
                border: "none",
              }}
              onClick={() => navigate(`/public-screeners/${category}`)}
            >
              More
            </button>
          </div>
        ))}

        {/* {Object.keys(allScreeners).map((category)=>(
          <div className="screener-list card" key={category}>
          <h3>{category}</h3>
          <div className="p-s-list">
           { allScreeners[category].map((item) => (
               <p key={item._id} style={{borderBottom: '1px solid rgba(0, 0, 0, 0.25)',padding: '8px', marginBottom: 0}}  onClick={()=>{navigate(`/public-screeners/${item._id}`)}}>{item.name}</p>
             ))
           }    
          </div>
          <button style={{float:'right', margin:'10px 0px 5px 0px', padding:'5px 20px',border:'none'}}>More</button>
        </div>
        ))} */}
      </div>

      <Footer />
    </>
  );
}
